import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  addDoc,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { Job, Notification } from "../../interface";
import { auth } from "../../services/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { v4 as uuidv4 } from "uuid";
import "./style.scss";
import { Notifictions } from "../../components/notifictionns";
import { HiBell, HiHand, HiOutlineTrash } from "react-icons/hi";

const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const db = getFirestore();
  const [user] = useAuthState(auth);
  const userId = user?.uid;
  const uid = auth.currentUser?.uid;
  const [users, setUsers] = useState<any>(null);
  const currentUser = auth.currentUser;
  const [acceptedNotifications, setAcceptedNotifications] = useState<string[]>(
    []
  );
  const horaAtual = new Date().toLocaleTimeString();

  const dataAtual = new Date().toLocaleDateString();

  interface WorkReques {
    value: any;
    title: string;
  }

  useEffect(() => {
    const storedAcceptedNotifications = localStorage.getItem(
      "acceptedNotifications"
    );
    if (storedAcceptedNotifications) {
      setAcceptedNotifications(JSON.parse(storedAcceptedNotifications));
    }
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            // console.log("Dados do usuário:", userData);
            setUsers(userData);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUsers();
  }, [uid, db]);

  useEffect(() => {
    const q = query(
      collection(db, "notifications"),
      where("userId", "==", userId)
    );  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedNotifications = snapshot.docs.map((doc) => {
        const data = doc.data() as Notification;
        const notificationId = doc.id;
        
        return { ...data, notificationId }; // Corrected from uid to uidN
      });
      setNotifications(updatedNotifications);
    });
  
    return () => {
      unsubscribe();
    };
  }, [db, userId]);

  const handleDeleteAllNotifications = async () => {
    try {
      const notificationsRef = collection(db, 'notifications');
      const userNotificationsQuery = query(notificationsRef, where('userId', '==', userId));
      const userNotificationsSnapshot = await getDocs(userNotificationsQuery);
      userNotificationsSnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      setNotifications([]); 
    } catch (error) {
      console.error('Error deleting notifications:', error);
    }
  };
  const theme = localStorage.getItem('theme')
  return (
    <main className={`notificationsTela ${theme}`}>
      <section className="container pt-5 pg-isMobiled-notification">
        <div className="row justify-content-center">
          <div className="col-xl-5">
            <div className="mt-5">
              <div className="mb-4 text-center-d">
              <h5 >Notificações
                {notifications.length !== 0 ? <span className="deletenotf ms-2" onClick={handleDeleteAllNotifications}><HiOutlineTrash/></span> : null}
                 </h5>
              </div>
              {notifications.reverse().map((notification) => (
                <div key={notification.notificationId}>
                  <Notifictions
                    linkUser={undefined}
                    requesterPhoto={notification.requesterPhoto}
                    requesterName={notification.requesterName}
                    message={notification.message}
                    notificationId={
                      notification.requestService === true
                        ? `/serviceRequest/${notification.requestServiceID}`
                        : `/jobDetails/${notification.jobId}`
                    }
                    icon={
                      notification.requestService === true ? (
                        <HiHand />
                      ) : (
                        <HiBell />
                      )
                    }
                    timestamp={notification.timestamp}
                  />
                  
                </div>
                
              ))}

              {notifications.length === 0 ? (
                <>
                  <p className="text-center mt-4">Sem notificações</p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Notifications;
