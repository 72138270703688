import React, { useState, useEffect } from "react";
import logo from "./../../asset/vector/logo.svg";
import "./style.scss";
import { HiOutlineUser, HiOutlineLockClosed } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebaseConfig";
import { Google } from "../../components/Google";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PageLoader } from "../../components/pageLoader";

const Login: React.FC = () => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Clique para voltar à página inicial
    </Tooltip>
  );
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userLocal = localStorage.getItem("user");
  const isButtonDisabled = email.trim() === '' || password.trim() === '';
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  useEffect(() => {
    if (error) {
      toast.error(
        "Ops! Ocorreu um erro ao efetuar o login. Por favor, verifique suas credenciais e tente novamente."
      );
    }
    if (loading) {
      toast.info("Estamos realizando o processo de login...");
    }
    if (user) {
      toast.success("Login efetuado com sucesso!");
      localStorage.setItem("user", JSON.stringify(user.user.uid));
      navigate("/dashboard");
    }
  });

  if (userLocal !== null) {
    window.location.href = "/dashboard";
    return <PageLoader />;
  }

  const theme = localStorage.getItem('theme')

  return (
    <>
      <section className={`p-4 pb-5 d-flex justify-content-center align-items-center body-login ${theme}`}>
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-lg-6 col-xl-3">
                <div className="text-img text-center mt-5 pb-4">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <Link to="/dashboard">
                      <img src={logo} alt="" className="mb-3" />
                    </Link>
                  </OverlayTrigger>
                  <h4>Bem-vindo ao Biscato</h4>
                  <p>Por favor, faça login na sua conta.</p>
                </div>
                <div className="input-group border mb-3">
                  <span className="input-group-text">
                    <HiOutlineUser />
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Seu Email"
                    required
                  />
                </div>

                <div className="input-group border mb-3">
                  <span className="input-group-text">
                    <HiOutlineLockClosed />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Sua Senha"
                    required
                  />
                </div>
                <Link className="a-link" to="/recover">
                  Esqueceu sua senha?
                </Link>
                <button
                  className="btn btn-primary mt-3 mb-3"
                  onClick={() => signInWithEmailAndPassword(email, password)}
                  disabled={isButtonDisabled}
                >
                  Entrar
                </button>
                <div className={`text-border mb-4 mt-2 d-flex justify-content-center ${theme}`}>
                  <span className="text">ou entrar com o Google</span>
                </div>
                <Google />
                <div className="mt-5 text-center">
                  Ainda não tem uma conta?{" "}
                  <Link className="a-link" to="/register">
                    Criar uma conta
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
      <ToastContainer />
    </>
  );
};

export default Login;
