import React, { useState, useEffect } from "react";
import userDefalt from "./../../asset/userDefalt.png";
import { PageLoader } from "../pageLoader";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useNavigate, NavLink, Navigate } from "react-router-dom";
import logo from "./../../asset/vector/logo_text.svg";
import logo2 from "./../../asset/vector/logo_text_w.svg";
import { ToastContainer, toast } from "react-toastify";
import "./style.scss";
import {
  HiOutlineBell,
  HiOutlineNewspaper,
  HiOutlineSun,
  HiMoon,
  HiOutlineUserGroup,
} from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebaseConfig";
import { ButtomCreateJob } from "../buttomCreateJob";

export const Menu: React.FC = () => {
  const [user] = useAuthState(auth);
  const userAut = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const uid = auth.currentUser?.uid;
  const userId = user?.uid;
  const db = getFirestore();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const navigate = useNavigate();
  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem("user");
    navigate("/login");
  };
  const notificationTooltip = (props: any) => (
    <Tooltip id="notification-tooltip" {...props}>
      Mostrar Notificações
    </Tooltip>
  );

  useEffect(() => {
    const getUserData = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUserData(userData);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          // console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUserData();
  }, [uid, db]);

  useEffect(() => {
    if (userId) {
      const q = query(
        collection(db, "notifications"),
        where("userId", "==", userId)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedNotifications = snapshot.docs.map(
          (doc) => doc.data() as Notification
        );
        setNotifications(updatedNotifications);
        setNotificationCount(updatedNotifications.length);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [db, userId]);

  const handleNotificationClick = () => {
    setNotificationCount(0);
    navigate("/notifications");
  };

  useEffect(() => {
    const storedNotificationCount = localStorage.getItem("notificationCount");
    if (storedNotificationCount !== null) {
      setNotificationCount(parseInt(storedNotificationCount));
    }
  }, []);

  useEffect(() => {
    // Armazene o contador de notificações no Local Storage sempre que ele for atualizado
    localStorage.setItem("notificationCount", notificationCount.toString());
  }, [notificationCount]);

  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      setIsDarkMode(true);
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // 2 minutos em milissegundos
  
    return () => clearTimeout(timer);
  }, []);

  const toggleTheme = () => {
    setIsLoading(true);
    const newTheme = isDarkMode ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    setIsDarkMode(!isDarkMode);
    document.body.classList.remove(isDarkMode ? 'dark' : 'light');
    document.body.classList.add(newTheme);
    toast.info(`${newTheme === 'dark' ? '🌙' : '☀️'} Tema alterado com sucesso!`);
    console.info(`${newTheme === 'dark' ? '🌙' : '☀️'} Tema alterado com sucesso!`);
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }, 2000)
};

  const theme = localStorage.getItem('theme')

  return (
    <>
    {isLoading && <PageLoader />}
    <nav className={`navbar border-bottom fixed-top ${theme}`}>
      <div className="container">
        <Link to="/dashboard" className="navbar-brand">
        {theme === "dark" ? <img src={logo2} alt="" width={80} /> : <img src={logo} alt="" width={80} />}
        </Link>  
        <div className="nav-menu">
          <nav className="nav">
            <NavLink className="nav-link" to="/dashboard">
              Encontrar Trabalho
            </NavLink>
            <Link className="nav-link" to="/professionals">
              Profissionais
            </Link>
            <Link className="nav-link" to="/jobs">
              Meus Trabalhos
            </Link>
            <ButtomCreateJob />
          </nav>
        </div>
        <div className="d-flex search " role="search">
          <div
            className="btn-group me-4"
            role="group"
            aria-label="Basic example"
          >
           {userAut[0] === null || userAut === null ? null :  <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={notificationTooltip}
            >
              <button
                onClick={handleNotificationClick}
                className="btn button button-HiOutlineBell position-relative"
              >
                <HiOutlineBell />
                {notificationCount !== 0 ? (
                  <>
                    <span className="position-absolute visually-notfocationPc   translate-middle  bg-danger border border-light rounded-circle">
                      <span className="visually-hidden">Novos Alertas</span>
                    </span>
                  </>
                ) : null}
              </button>
            </OverlayTrigger>}
          </div>

          {userAut[0] === null || userAut === null ? (
            <div className="pt-2">
              <Link to="/login" className="a-link">
                Entrar
              </Link>
            </div>
          ) : (
            <div className="d-flex">
              {userData && userData.isActive ? (
                <div className="badge badge-statu-user me-3">Sou Cliente</div>
              ) : userData && userData.isActive === false ? (
                <div className="badge badge-statu-user me-3">
                  Sou Profissional
                </div>
              ) : null}

              <Dropdown>
                <Dropdown.Toggle id={`dropdown-basic-button dropdownavantar ${theme}`}>
                  <div className="avatar">
                    <img src={userData?.photoURL || userDefalt} alt="" />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`shadow-sm border-0 dropdowdn ${theme}`}>
                  <div className="container border-bottom pt-2 pb-3 mb-2">
                    <div className="d-flex"> 
                      <div className="avatar">
                        <img src={userData?.photoURL || userDefalt} alt="" />
                      </div>
                      <div className="nameEmil ms-2">
                        <h6>
                          {userData?.displayName &&
                          userData.displayName.length > 30
                            ? `${userData.displayName.substring(0, 20)}...`
                            : userData?.displayName}
                        </h6>

                        <div className="span">{userData?.email}</div>
                      </div>
                    </div>
                  </div>
                  
                  <li> 
                    <Link className="dropdown-item" to="/profile">
                      Meu Perfil
                    </Link>
                  </li>
                  <button className="dropdown-item" onClick={() => toggleTheme()}>
                  {isDarkMode ? <>Modo Claro <HiOutlineSun className="hiOutlineSunIcon ms-1"/></> : <>Modo Escuro <HiMoon className="hiMoonIcon ms-1"/></> }
                </button>
                  {/* <li>
                    <Link className="dropdown-item" to="/">
                      Emprego
                    </Link>
                  </li>                   */}
                  <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </nav>
    </>
  );
};

interface menuIsMobiled {
  btnMenu: any;
}

export const MenuIsMobile: React.FC<menuIsMobiled> = (props) => {
  const [user] = useAuthState(auth);
  const userAut = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const uid = auth.currentUser?.uid;
  const userId = user?.uid;
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const db = getFirestore();
  const navigate = useNavigate();
  useEffect(() => {
    const getUserData = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUserData(userData);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          // console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUserData();
  }, [uid, db]);
  useEffect(() => {
    if (userId) {
      const q = query(
        collection(db, "notifications"),
        where("userId", "==", userId)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedNotifications = snapshot.docs.map(
          (doc) => doc.data() as Notification
        );
        setNotifications(updatedNotifications);
        setNotificationCount(updatedNotifications.length);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [db, userId]);
 
  useEffect(() => {
    const storedNotificationCount = localStorage.getItem("notificationCount");
    if (storedNotificationCount !== null) {
      setNotificationCount(parseInt(storedNotificationCount));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("notificationCount", notificationCount.toString());
  }, [notificationCount]);

  const handleNotificationClick = () => {
    setNotificationCount(0);
    navigate("/notifications");
  };
  const theme = localStorage.getItem('theme')
  return (
    <div
      className={`navbar-isMobiled  btn-group navbar border-top fixed-bottom ${theme}`}
      role="group"
    >
      <NavLink className="btn btn-menu-bt" to="/dashboard">
        <HiOutlineNewspaper />
      </NavLink>
      <NavLink className="btn btn-menu-bt" to="/professionals">
        <HiOutlineUserGroup />
      </NavLink>
      {userData && userData.isActive ? (
        <ButtomCreateJob />
      ) : userData && userData.isActive === false ? null : null}
      <NavLink
        className="btn btn-menu-bt  position-relative"
        onClick={handleNotificationClick}
        to='/notifications'
      >
        <HiOutlineBell />
        {notificationCount !== 0 ? (
          <>
            <span className="position-absolute visually-notfocationphn  translate-middle bg-danger border border-light rounded-circle">
              <span className="visually-hidden">Novos Alertas</span>
            </span>
          </>
        ) : null}
      </NavLink>
      {props.btnMenu}
      <ToastContainer/>
    </div>
  );
};
