import React, { useEffect, useState } from "react";
import "./style.scss";
import logo from "./../../asset/vector/logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  HiOutlineViewGrid,
  HiOutlineUsers,
  HiOutlineUser,
  HiOutlineBookmarkAlt,
  HiOutlineBriefcase,
  HiOutlineClipboardList,
  HiOutlineLogout,
  HiOutlineCog,
  HiOutlineChatAlt,
  HiOutlineNewspaper,
  HiMenuAlt1,
  HiOutlineDotsVertical,
  HiOutlineUserGroup,
  HiOutlineHome,
  HiOutlineSun,
  HiMoon,
} from "react-icons/hi";
import userS from "./../../asset/43.jpg";
import { auth } from "../../services/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { ToastContainer, toast } from "react-toastify";
import { PageLoader } from "../pageLoader";
export const MenuSidebar: React.FC = () => {
  const navigate = useNavigate();
  const userAut = useAuthState(auth);
  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem("user");
    navigate("/login");
  };
  const handleSing = () => {
    navigate("/login");
  };
  const [menuItems, setMenuItems] = useState([
    {
      title: "Encontrar Trabalhos",
      link: "/dashboard",
      icon: <HiOutlineNewspaper />,
    },
    {
      title: "Meus Trabalhos",
      link: "/jobs",
      icon: <HiOutlineBriefcase />,
    },
    {
      title: "Meu Perfil",
      link: "/profile",
      icon: <HiOutlineUser />,
    },
  ]);


  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      setIsDarkMode(true);
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // 2 minutos em milissegundos
  
    return () => clearTimeout(timer);
  }, []);

  const toggleTheme = () => {
    setIsLoading(true);
    const newTheme = isDarkMode ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    setIsDarkMode(!isDarkMode);
    document.body.classList.remove(isDarkMode ? 'dark' : 'light');
    document.body.classList.add(newTheme);
    toast.info(`${newTheme === 'dark' ? '🌙' : '☀️'} Tema alterado com sucesso!`);
    console.info(`${newTheme === 'dark' ? '🌙' : '☀️'} Tema alterado com sucesso!`);
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }, 2000)
};

  const theme = localStorage.getItem('theme')

  return (
    <>
      {isLoading && <PageLoader />}
    <div className={`MenuSidebar ${theme}`}>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
      </div>
      <nav>
        {/* <h6>Menu</h6> */}
        {menuItems.map((menuItem) => (
          <div key={menuItem.title}>
            <NavLink to={menuItem.link} className={"input-group navLink"}>
              <span className="input-group-text">{menuItem.icon}</span>
              {menuItem.title}
            </NavLink>
          </div>
        ))}

        <button
         onClick={() => toggleTheme()}
          className={"input-group navLink btn-handleLogout"}
        >
          <span className="input-group-text">
          {isDarkMode ? <HiOutlineSun /> : <HiMoon />}
          </span>
          {isDarkMode ? <>Modo Claro</> : <>Modo Escuro</>}
        </button>

        {userAut === null || userAut[0] === null ? (
          <button
            onClick={handleSing}
            className={"input-group navLink btn-handleLogout"}
          >
            <span className="input-group-text">
              <HiOutlineLogout />
            </span>
            Entrar
          </button>
        ) : (
          <button
            onClick={handleLogout}
            className={"input-group navLink btn-handleLogout"}
          >
            <span className="input-group-text">
              <HiOutlineLogout />
            </span>
            Sair
          </button>
        )}
      </nav>
    </div>
    </>
  );
};
