import React from "react";
import "./style.scss";
import user from './../../asset/43.jpg'
import {
  HiBell,
  HiOutlineBell,
  HiOutlineFire,
  HiOutlineTrash,
} from "react-icons/hi";
import { Link } from "react-router-dom";

interface notification {
  linkUser: any;
  requesterPhoto: any;
  requesterName: any;
  message: any;
  notificationId:any
  icon: any
  timestamp:any 
}
const theme = localStorage.getItem('theme')
export const Notifictions: React.FC <notification> = (props) => {
  return (
    <Link to={props.notificationId} className={`card card-notification pb-2 mb-3 ${theme}`}>
      <div className="card-body d-flex align-items-center">
        <div className="avatar-notification">
          <Link className="link-user-prof" to=''>
            <img src={props.requesterPhoto} alt="" />
          </Link>
          <div className="icon-notification">
            {props.icon}
          </div>
        </div>
        <div>
          <b>{props.requesterName}</b> {props.message} <br /> 
          <div className="timestamp">{props.timestamp}</div>
          {/* <br /> ha 10:304 */}
        </div>
        </div>
    </Link>
  );
};
