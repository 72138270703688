/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { FaFacebookF, FaGooglePlay, FaLinkedinIn, FaWhatsapp, FaWhatsappSquare, FaYoutube } from "react-icons/fa";
import "./style.scss";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import img1 from "../../asset/img/img1.png";
import img2 from "../../asset/img/img2.png";
import img3 from "../../asset/img/img3.png";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../asset/vector/logo.svg";
import logoText from "./../../asset/vector/logo_text.svg";
import logoText2 from "./../../asset/vector/logo_text_w.svg";
import { auth } from "./../../services/firebaseConfig";
import { Post, Skill } from "../../interface";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../key";
import { HiMoon, HiOutlineNewspaper, HiOutlineSun } from "react-icons/hi";
import { Tooltip } from "react-bootstrap";

const Home: React.FC = () => {

  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      setIsDarkMode(true);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = isDarkMode ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    setIsDarkMode(!isDarkMode);
    document.body.classList.remove(isDarkMode ? 'dark' : 'light');
    document.body.classList.add(newTheme);
    toast.info(`${newTheme === 'dark' ? '🌙' : '☀️'} Tema alterado com sucesso!`);
    console.info(`${newTheme === 'dark' ? '🌙' : '☀️'} Tema alterado com sucesso!`);
    setTimeout(() => {
      location.reload()
    }, 2000)
  };



  const firebaseConfigDB = initializeApp(firebaseConfig);
  const userAut = useAuthState(auth);
  const [skillss, setSkillss] = useState<Skill[]>([]);
  // const [suggestions, setSuggestions] = useState<Skill[]>([]);
  const db = getFirestore(firebaseConfigDB);
  // const skillsCollection = collection(db, "skills");
  // const [skills, setSkills] = useState<Skill[]>([]);
  // useEffect(() => {
  //   const getPosts = async () => {
  //     const querySnapshot = await getDocs(skillsCollection);
  //     const postData = querySnapshot.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     })) as Post[];
  //     localStorage.setItem("skills", JSON.stringify(postData));
  //     const storedSkills = localStorage.getItem("skills");
  //     if (storedSkills) {
  //       const parsedSkills = JSON.parse(storedSkills) as Skill[];
  //       console.log("Data retrieved from localStorage:", parsedSkills);
  //       setSkills(parsedSkills);
  //     } else {
  //       console.log("No data found in localStorage");
  //       setSkills([]);
  //     }
  //   };
  //   getPosts();
  // }, []);
  // useEffect(() => {
  //   // Carregue os dados do localStorage quando o componente for montado
  //   const storedSkills = localStorage.getItem("skills");
  //   if (storedSkills) {
  //     const parsedSkills = JSON.parse(storedSkills) as Skill[];
  //     setSkillss(parsedSkills);
  //   }
  // }, []);

  const theme = localStorage.getItem('theme')



  return (
    <>
      {/* <nav className="navbar navbar-light bg-white pt-3 pb-3 border-bottom fixed-top">
      <div className="container">
        <a href="/"><img src={logoText} width={80} alt='img'/></a>
        <div className="d-flex">
          <Link to="/login" className="btn-light bg-white border-0 a_link_hum" title='Entrar na pagina'>Entrar</Link>
        </div>
      </div>
    </nav> */}
      <div className={`home  ${theme}`}>
        <nav className={`navbar   border-bottom fixed-top ${theme}`}>
          <div className="container">
            <Link className="navbar-brand" to="/dashboard">
              {theme === "dark" ? <img src={logoText2} alt="" width={80} /> : <img src={logoText} alt="" width={80} />}
            </Link>

            <div className="d-flex" role="search">
              <nav className="nav">
                <Link className="nav-link" aria-current="page" to="/dashboard">
                  Trabalhos
                </Link>
                <Link className="nav-link" to="/professionals">
                  Profissionais
                </Link>
                <button className="btn btn-dark rounded-pill" onClick={() => toggleTheme()}>
                  {isDarkMode ? <HiOutlineSun className="HiOutlineSunHome" /> : <HiMoon className="HiOutlineSunHome" />}
                  {isDarkMode ? <>Modo Claro</> : <>Modo Escuro</>}
                </button>
              </nav>
            </div>
          </div>
        </nav>

        <div className={`h-100 d-flex align-items-center ${theme}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center">
                <div className="mt-5 pt-5">
                  <h1 className="display-4">
                    Conectando quem precisa com quem sabe fazer
                  </h1>
                  <h5 className="opacity-50">
                    Fale o que precisa, receba até 10 orçamentos e escolha o
                    melhor.
                  </h5>
                  {/* {userAut === null || userAut[0] === null ? (
                    <Link
                      to="/login"
                      className="btn btn-primary me-3 pe-4 mt-3 ps-4 pt-2_5"
                    >
                      Entrar
                    </Link>
                  ) : null}

                  <Link
                    to="/dashboard"
                    className="btn btn-light  pe-4 mt-3 pt-2_1 ps-4"
                  >
                    Ver serviços
                  </Link> */}
                  <div className="mt-4">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.biscato.net"
                      className="btn btn-links-banner btn-light me-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaGooglePlay className="mr-3" /> Baixar na Google Play
                    </a>

                    <Link aria-current="page" to="/dashboard" className="btn btn-links-banner btn-dark me-3">
                      <HiOutlineNewspaper className="mr-3" /> Encontrar trabalho
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <img src={img1} className="img-fluid mt-5" alt="img" />
              </div>
            </div>
          </div>
        </div>
        <div className={`div-text-cards ${theme}`}>
          <div className="bg-green pt-100 pb-180">
            <div className="container text-center">
              <h2>O que é o biscato?</h2>
              <h5 className="mt-3">
                O Biscato é a maior plataforma de contratação de serviços de Angola.
                Conectamos profissionais <br />de toda Angola com pessoas que precisam de
                serviços, atendendo com qualidade, facilidade e rapidez todos os tipos
                de necessidades.
              </h5>
            </div>
          </div>
          <div className={`container mt-n100 ${theme}`}>
            <div className="row">
              <div className="col-sm-4 mb-5">
                <div className={`card border-0 ${theme}`}>
                  <div className="card-body p-20">
                    <img src={logo} width={50} className="mb-4" alt="img" />
                    <h4>
                      <b>Faça o seu pedido</b>
                    </h4>
                    Diga-nos o que você precisa. É rápido e gratuito!
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-5">
                <div className={`card border-0 ${theme}`}>
                  <div className="card-body p-20">
                    <img src={logo} width={50} className="mb-4" alt="img" />
                    <h4>
                      <b>Receba até 10 orçamentos</b>
                    </h4>
                    Profissionais qualificados entrarão em contato com você em instantes!
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-5">
                <div className={`card border-0 ${theme}`}>
                  <div className="card-body p-20">
                    <img src={logo} width={50} className="mb-4" alt="img" />
                    <h4>
                      <b>Escolha o melhor</b>
                    </h4>
                    Negocie diretamente com eles. Fácil como nunca antes!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`h-100 d-flex align-items-center ${theme}`}>
          <div className="container ${theme}">
            <div className="row">
              <div className="col-sm-5 d-flex align-items-center mb-5">
                <img src={img2} className="img-fluid" alt="img" />
              </div>
              <div className="col-sm-5 d-flex align-items-center mb-5">
                <div>
                  <h1>Por que deves usar o biscato</h1>
                  <h5 className="mt-4">Sem custo até você contratar</h5>O
                  potencial de entrevista se encaixa no seu trabalho, negocie
                  taxas e pague apenas pelo trabalho que você aprovar.
                  <h5 className="mt-4">Seguro e protegido</h5>
                  Concentre-se em seu trabalho sabendo que ajudamos a proteger
                  seus dados e privacidade. Estamos aqui com suporte 24 horas
                  por dia, 7 dias por semana, se você precisar.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-black h-100 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 mb-5 d-flex align-items-center">
                <div>
                  <h1>Encontre um ótimo profissional</h1>
                  <p>
                    Conheça profissional com os quais você está animado para
                    trabalhar e leve sua carreira ou negócios a novos patamares.
                  </p>
                  <button type="button" className="btn btn-light mt-4">
                    Encontrar Profissional
                  </button>
                </div>
              </div>
              <div className="col-sm-6 d-flex mb-5 align-items-center">
                <img src={img3} className="img-fluid" alt="img" />
              </div>
            </div>
          </div>
        </div>
        <div className={`container text-center pt-100 pb-100 ${theme}`}>
          <h2 className="mb-5">Como o biscato funciona para o Profissional?</h2>
          <div className="row mt-5 justify-content-center">
            <div className="col-sm-3">
              <div className="card-body p-2">
                <img src={logo} alt="" width={50} className="mb-4" />
                <p>
                  Pedidos de serviços chegando toda hora no seu celular ou
                  computador.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card-body p-2">
                <img src={logo} alt="" width={50} className="mb-4" />
                <p>
                  Veja todos os pedidos de graça, e invista apenas nos que
                  gostar.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card-body p-2">
                <img src={logo} alt="" width={50} className="mb-4" />
                <p>95% do valor do serviço é seu. E sem mensalidade!</p>
              </div>
            </div>
          </div>
        </div>
        <footer className={`border-top  ${theme}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-2 text-center pb-3">
                <a
                  href="https://www.facebook.com/Biscato-109372028547022"
                  className="me-3 link-icon-reds"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.linkedin.com/company/biscato"
                  className="me-3 link-icon-reds"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCdQxe8pzeVWHiHKsSOiI_GA"
                  className="me-3 link-icon-reds"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />
                </a>
                <a
                  href="https://whatsapp.com/channel/0029Va8Onyl4yltOBg0IZE1K"
                  className="me-3 link-icon-reds"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsappSquare />
                </a>
              </div>
              <div className="col-lg-10 pb-3 d-flex justify-content-end opacity-50">
                <Link className="nav-link font-14" aria-current="page" to="/terms">
                  Termos & Política de Privacidade
                </Link>{" "}
                <span className="me-2 font-14 ms-2">|</span>{" "}
                <span className="font-14">© 2023 Biscato. Todos os direitos reservados.</span>
              </div>
            </div>
          </div>
          <div></div>
        </footer>
      </div>
      <div>
        {/* {skillss.map((skill) => (
  <div key={skill.id}>
    {skill.name}
  </div>
))} */}
      </div>
      <ToastContainer />
    </>
  );
};
export default Home;