import React from "react";
import "./style.scss";
import logo from "./../../asset/vector/logo.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { HiLocationMarker, HiBookmark, HiOutlineSparkles } from "react-icons/hi";
import { Link } from "react-router-dom";

interface cardRequestsInter {
  title: string; // Título do post
  description: string; // Descrição do post
  date: any; // Data do post (formato string)
  location: string; // Localização do post
  details: string; // Detalhes do post
  value: number; // Valor do post
  skill: string[];
  publish_date: string; // Data de publicação do post (formato string)
  publish_time: string; // Hora de publicação do post (formato string)
  link: string;
  btns: any;
}
interface cardRequestsInterJobs {
  title: string; // Título do post
  description: string; // Descrição do post
  date: any; // Data do post (formato string)
  location: string; // Localização do post
  details: string; // Detalhes do post
  value: number; // Valor do post
  skill: string[];
  publish_date: string; // Data de publicação do post (formato string)
  publish_time: string; // Hora de publicação do post (formato string)
  link: string;
  btn: any;
}

export const CardRequestsJob: React.FC<cardRequestsInter> = (props) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Saber mais
    </Tooltip>
  );
  const renderTooltip2 = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Guardar publicação
    </Tooltip>
  );

  const theme = localStorage.getItem('theme')
  return ( 
    <div className={`card border card-requests ${theme}`}>
      <div className="card-header justify-content-between d-flex">
        <div>
          <h5>
            <b>{props.title}</b>
          </h5>
          <span>
            <HiLocationMarker /> {props.location} - Postado {props.publish_time}{" "}
            de {props.publish_date}
          </span>
        </div>
        <div className="ps-3">{props.btns}</div>
      </div>
      <div className="card-body pt-0 pb-0">
        <div>
          {Array.isArray(props.skill) &&
            props.skill.map((skill: string, index: number) => (
              <span className="badge me-2" key={index}>
                {skill}
              </span>
            ))}
        </div>
      </div>
      <div className="card-body">
        {props.description.length > 300
          ? `${props.description.slice(0, 300)}...`
          : props.description}
        <h5 className="mt-3">
          <HiOutlineSparkles/> 
        
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "AOA",
            })
              .format(props.value)
              .replace("AOA", "Kz")}
          <b> /hr{" "}</b>
        </h5>
      </div>
      <div className="card-footer pb-0 pt-0 d-flex justify-content-between">
        <div>{/* propostas: <b>00</b> */}</div>
        <div>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <div className="btn-viw-request-div">
              <Link
                to={props.link}
                className="btn-viw-request pt-2 pb-2 border-0 ps-3 pe-3"
              >
                Ver trabalho
              </Link>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export const CardRequests: React.FC<cardRequestsInter> = (props) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Saber mais
    </Tooltip>
  );
  const renderTooltip2 = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Guardar publicação
    </Tooltip>
  );
  const theme = localStorage.getItem('theme')
  return (
    <Link
      to={props.link}
      className={`card border card-requests ${theme}`}
    >
      <div className="card-header justify-content-between d-flex">
        <div>
          <h5>
            <b>{props.title}</b>
          </h5>
          <span>
            <HiLocationMarker /> {props.location} - Postado {props.publish_time}{" "}
            de {props.publish_date}
          </span>
        </div>
        <div className="ps-3">{props.btns}</div>
      </div>
      <div className="card-body pt-0 pb-0">
        <div>
          {Array.isArray(props.skill) &&
            props.skill.map((skill: string, index: number) => (
              <span className="badge me-2" key={index}>
                {skill}
              </span>
            ))}
        </div>
      </div>
      <div className="card-body">
        {props.description.length > 300
          ? `${props.description.slice(0, 300)}...`
          : props.description}
        <h5 className="mt-3">
        <HiOutlineSparkles/> 
          
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "AOA",
            })
              .format(props.value)
              .replace("AOA", "Kz")}
          <b> /hr{" "}</b>
        </h5>
      </div>
      {/* <div className="card-footer pt-0 d-flex justify-content-between">
    <div>
      propostas: <b>00</b>
    </div>
    <div>
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Link to={`/jobDetails/${props.link}`} className='shadow-sm'>
        <img src={logo} alt="" />
      </Link>
    </OverlayTrigger>
    </div>
  </div> */}
    </Link>
  );
};

export const CardRequestsJobs: React.FC<cardRequestsInterJobs> = (props) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Saber mais
    </Tooltip>
  );
  const renderTooltip2 = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Guardar publicação
    </Tooltip>
  );

  const theme = localStorage.getItem('theme')
  return (
    <div className={`card border card-requests mb-4 ${theme}`}>
      <div className="card-header justify-content-between d-flex">
        <div>
          <h5>
            <b>{props.title}</b>
          </h5>
          <span>
            <HiLocationMarker /> {props.location} - Postado {props.publish_time}{" "}
            de {props.publish_date}
          </span>
        </div>
        {/* <div className='ps-3'>
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip2}
    >
    <button onClick={salveJobs} className='guardar'>
      <HiBookmark/>
    </button>
    </OverlayTrigger>
    </div> */}
      </div>
      <div className="card-body pt-0 pb-0">
        <div>
          {Array.isArray(props.skill) &&
            props.skill.map((skill: string, index: number) => (
              <span className="badge me-2" key={index}>
                {skill}
              </span>
            ))}
        </div>
      </div>
      <div className="card-body">
        {props.description.length > 300
          ? `${props.description.slice(0, 300)}...`
          : props.description}
        <h5 className="mt-3">
          <HiOutlineSparkles/> 
          
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "AOA",
            })
              .format(props.value)
              .replace("AOA", "Kz")}
          <b> /hr{" "}</b>
        </h5>
      </div>
      <div className="card-footer pb-0 pt-0 d-flex justify-content-between">
        <div>{/* {props.btn} */}</div>
        <div>{props.btn}</div>
      </div>
    </div>
  );
};

export const CardRequestsService: React.FC<cardRequestsInter> = (props) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Saber mais
    </Tooltip>
  );
  const renderTooltip2 = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Guardar publicação
    </Tooltip>
  );

  const theme = localStorage.getItem('theme')

  return (
    <div className={`card border card-requests ${theme}`}>
      <div className="card-header justify-content-between d-flex">
        <div>
          <h5>
            <b>{props.title}</b>
          </h5>
          <span>
            <HiLocationMarker /> {props.location} - Postado {props.publish_time}{" "}
            de {props.publish_date}
          </span>
        </div>
        <div className="ps-3">{props.btns}</div>
      </div>
      <div className="card-body pt-0 pb-0">
        <div>
          {Array.isArray(props.skill) &&
            props.skill.map((skill: string, index: number) => (
              <span className="badge me-2" key={index}>
                {skill}
              </span>
            ))}
        </div>
      </div>
      <div className="card-body">
        {props.description.split("\n").map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        <h5 className="mt-3">
          <HiOutlineSparkles/> 
          
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "AOA",
            })
              .format(props.value)
              .replace("AOA", "Kz")}
          <b> /hr{" "}</b>
        </h5>
      </div>
      {/* <div className="card-footer pt-0 d-flex justify-content-between">
    <div>
      propostas: <b>00</b>
    </div>
    <div>
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Link to={`/jobDetails/${props.link}`} className='shadow-sm'>
        <img src={logo} alt="" />
      </Link>
    </OverlayTrigger>
    </div>
  </div> */}
    </div>
  );
};
