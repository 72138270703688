import React, { useEffect, useState } from "react";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  addDoc,
  // setDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import Slider from "react-slick";
import logo from "./../../asset/vector/logo.svg";
import firebaseConfig from "../../key";
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import capa from "./../../asset/capa.png";
import { UserInfo, Notification, Post, PostService } from "../../interface";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebaseConfig";
import Modal from "react-bootstrap/Modal";
import Iframe from "react-iframe";
import {
  HiOutlineFire,
  HiOutlineLightBulb,
  HiOutlineLightningBolt,
  HiOutlineLocationMarker,
  HiOutlineNewspaper,
  HiOutlinePlay,
} from "react-icons/hi";
import Rodape from "../../components/rodape";
import "./style.scss";
import { InputForm, InputFormTextArea } from "../../components/inputForm";
const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const UserDetails: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const { id } = useParams();
  const userId = id ?? "";
  const auth = getAuth(firebaseApp);
  const currentUser = auth.currentUser;

  const [user] = useAuthState(auth);
  const userAut = useAuthState(auth);
  const [users, setUsers] = useState<any>(null);
  const uid = auth.currentUser?.uid;
  const db = getFirestore();
  const [titulo, setTitulo] = useState("");
  const [valor, setValor] = useState("");
  const [descricao, setDescricao] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [btnShowSendWorkRequest, setShowBtnShowSendWorkRequest] =
    useState(true);

  const [showSendWorkRequest, setShowSendWorkRequest] = useState(false);

  const handleCloseSendWorkRequest = () => setShowSendWorkRequest(false);
  const handleShowSendWorkRequest = () => setShowSendWorkRequest(true);

  const handleCloseVideo = () => setShowVideo(false);
  const handleShowVideo = () => setShowVideo(true);

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Conta verificada
    </Tooltip>
  );

  useEffect(() => {
    const getUsers = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const users = docSnapshot.data();
            // console.log("Dados do usuário:", users);
            setUsers(users);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUsers();
  }, [uid, db]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(firestore, "users", userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        } else if (userDocSnap === undefined) {
          // console.log("Não existe um ID");
        } else {
          // console.log("Usuário não encontrado");
        }
      } catch (error) {
        console.error("Erro ao buscar os dados do usuário:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  if (!userData) {
    return <p>Carregando dados do usuário...</p>;
  }
  const horaAtual = new Date().toLocaleTimeString();
  const dataAtual = new Date().toLocaleDateString();

  const sendWorkRequest = async (
    userId: any,
    titulo: string,
    valor: string,
    descricao: string
  ) => {
    setShowBtnShowSendWorkRequest(false);
    const uid = userId;
    const localStorageKey = `lastRequestTime_${uid}`;
    const lastRequestTime = localStorage.getItem(localStorageKey);
    
    const TWENTY_FOUR_HOURS_MS = 24 * 60 * 60 * 1000;
    
    if (!lastRequestTime) {
      // Se o item não existir, defina-o no localStorage com o valor atual
      const currentTime = new Date().getTime();
      localStorage.setItem(localStorageKey, currentTime.toString());
    } else {
      const currentTime = new Date().getTime();
      const lastRequestTimestamp = parseInt(lastRequestTime, 10);
    
      if (currentTime - lastRequestTimestamp < TWENTY_FOUR_HOURS_MS) {
        const errorMessage =
          "Você só pode enviar uma nova solicitação a cada 24 horas.";
        toast.info(errorMessage, {
          autoClose: 8000,
        });
        return;
      }
    }
    

    toast.success("Solicitação de serviço enviada com sucesso!", {
      autoClose: 8000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 5000);

    if (userId) {
      const serviceRequestData: PostService = {
        title: titulo,
        description: descricao,
        user_id: user?.uid,
        date: dataAtual,
        time: horaAtual,
        location: `${userData.location.selectedProvincia} / ${userData.location.selectedMunicipio}`,
        value: valor,
        skill: userData?.skills,
        publish_date: dataAtual,
        publish_time: horaAtual,
        userRequest: userData.uid,
        serviceActive: false,
      };

      try {
        const serviceRequestCollection = collection(
          firestore,
          "servicerequest"
        );
        const docRef = await addDoc(
          serviceRequestCollection,
          serviceRequestData
        );

        // console.log("Solicitação de serviço enviada com sucesso!");

        const notification: Notification = {
          userId: userData.uid,
          message: "solicitou um serviço",
          timestamp: `${dataAtual} - ${horaAtual}`,
          isRead: false,
          proposalId: "",
          jobId: "",
          requesterName: users?.displayName || "",
          requesterId: users?.uid || "",
          requesterPhoto: users?.photoURL || "",
          requesterPhoneNumber: users?.phoneNumber || "",
          notificationId: uuidv4(),
          requestService: true,
          requestServiceID: docRef.id, // Use o uid do documento recém-criado
        };

        await addDoc(collection(db, "notifications"), notification);
      } catch (error) {
        console.error("Erro ao enviar a solicitação de serviço:", error);
      }
    } else {
      // console.log("ID do usuário não está definido");
    }
  };

  // Definição dos tipos de dados
  type Certification = {
    title: string;
    description: string;
    date: string;
    pdfURL: string;
  };

  type Portfolio = {
    linkURL: string | undefined;
    imageURL: string;
    title: string;
    description: string;
    date: string;
    pdfURL: string;
  };

  const portfolioLength = userData?.portfolio?.length || 0;
  const certificationsLength = userData?.certifications?.length || 0;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(2, certificationsLength),
    slidesToScroll: Math.min(2, certificationsLength), // Rola a mesma quantidade de itens que são exibidos
  };

  const settingsJobs = {
    dots: true, 
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(2, portfolioLength),
    slidesToScroll: Math.min(2, portfolioLength), // Rola a mesma quantidade de itens que são exibidos
  };
  const theme = localStorage.getItem('theme')
  return (
    <>
      <main className={`profile ${theme}`}>
        <section>
          <div className="container pg-isMobiled-profilr pt-5">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-xl-8 pb-5">
                <div className={`card card-profile border shadow-none mt-5 ${theme}`}>
                  <div
                    className="capa"
                    style={{
                      backgroundImage: `url(${
                        userData?.coverPhotoURL || capa
                      })`,
                    }}
                  />
                  <div className="card-body pb-0">
                    <div className="inforUser d-flex">
                      <div className="photoURL">
                        <img src={userData?.photoURL || ""} alt="" />
                      </div>
                      <div className="ms-3 divDDDisplay">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <h4>{userData?.displayName &&
                          userData.displayName.length > 60
                            ? `${userData.displayName.substring(0, 20)}...`
                            : userData?.displayName}</h4>
                            {userData?.verified ? (
                              <div className="verified ms-3">
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <img src={logo} alt="" />
                                </OverlayTrigger>
                              </div>
                            ) : null}
                          </div>
                          <div>
                            {userData &&
                            userData.introductionVideoURL !== undefined ? (
                              <>
                                <button
                                  className="btn-play"
                                  onClick={handleShowVideo}
                                >
                                  <HiOutlinePlay />
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="local">
                          <HiOutlineLocationMarker className="mr-2" />
                          {userData && userData.location.selectedProvincia
                            ? userData.location.selectedProvincia
                            : "Provincia não disponível"}{" "}
                          /
                          {userData && userData.location.selectedMunicipio
                            ? userData.location.selectedMunicipio
                            : "Município não disponível"}
                          {/* <span className="ms-3">
                            Utola:{" "}
                            {userData && userData.utolaCode
                              ? userData.utolaCode
                              : "Código Utola não disponível"}
                          </span> */}
                          <div className="mt-2">
                            Email:{" "}
                            {userData && userData.email
                              ? userData.email
                              : "Email não disponível"}{" "}
                            <br /> Tel:{" "}
                            {userData && userData.phoneNumber
                              ? userData.phoneNumber
                              : "Número de telefone não disponível"}
                          </div>
                        </div>
                        <div className="sk mt-3">
                          {Array.isArray(userData?.skills) ? (
                            userData.skills.map(
                              (skill: string, index: number) => (
                                <span
                                  className="badge pe-3 ps-3 me-2"
                                  key={index}
                                >
                                  {skill}
                                </span>
                              )
                            )
                          ) : (
                            <span>Nenhuma habilidade disponível</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`mt-2 divDDisplay ${theme}`}>
                      <div className="d-flex">
                        <div>
                          <h4>{userData?.displayName &&
                          userData.displayName.length > 50
                            ? `${userData.displayName.substring(0, 20)}...`
                            : userData?.displayName}</h4>
                        </div>
                        {userData?.verified ? (
                          <div className="verified ms-3">
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <img src={logo} alt="" />
                            </OverlayTrigger>
                          </div>
                        ) : null}
                      </div>
                      <div className="local">
                        {userData && userData.location.selectedProvincia
                          ? userData.location.selectedProvincia
                          : "Provincia não disponível"}{" "}
                        /
                        {userData && userData.location.selectedMunicipio
                          ? userData.location.selectedMunicipio
                          : "Município não disponível"}{" "}
                        <span className="ms-3">
                          Utola:{" "}
                          {userData && userData.utolaCode
                            ? userData.utolaCode
                            : "Código Utola não disponível"}
                        </span>
                      </div>
                      <div className="opacity-50 font-14">
                        Email:{" "}
                        {userData && userData.email
                          ? userData.email
                          : "Email não disponível"}{" "}
                        <br /> Tel:{" "}
                        {userData && userData.phoneNumber
                          ? userData.phoneNumber
                          : "Número de telefone não disponível"}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer border-0 pt-0 d-flex justify-content-between ">
                    <div>
                      {users?.isActive !== false ? (
                        <button
                          className="sendWorkRequest"
                          onClick={handleShowSendWorkRequest}
                        >
                          <HiOutlineLightningBolt className="HiOutlineLightningBolt" />
                          Solicitar Serviço
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={`card divDDisplay card-about mt-4 border shadow-none ${theme}`}>
                  <div className="card-header pb-0 d-flex justify-content-between">
                    <div>
                      <b>Hablidades</b>
                    </div>
                  </div>
                  <div className="card-body pt-1 sk pb-4">
                    {Array.isArray(userData?.skills) &&
                    userData.skills.length > 0 ? (
                      userData.skills.map((skill: string, index: number) => (
                        <span className="badge pe-3 ps-3 me-2 mt-1" key={index}>
                          {skill}
                        </span>
                      ))
                    ) : (
                      <span>Nenhuma habilidade encontrada</span>
                    )}
                  </div>
                </div>
                <div className={`card card-about mt-4 card-profile border shadow-none ${theme}`}>
                  <div className="card-header pb-0">
                    <b>Sobre</b>
                  </div>
                  <div className="card-body pb-4">
                    {userData && userData.about
                      ? userData.about
                          .split("\n")
                          .map(
                            (
                              paragraph: any,
                              index: React.Key | null | undefined
                            ) => <p key={index}>{paragraph}</p>
                          )
                      : "Informações não disponíveis"}
                  </div>
                </div>
                {portfolioLength !== 0 ? <div className="mt-4 mb-5">
                  <h4 className="mb-3">Meus trabalhos</h4>
                  <Slider {...settingsJobs}>
                     {userData?.portfolio?.map(
                      (portfolio: Portfolio, index: number) => (
                        <div className="p-1">
                          <div className={`card border card-portfolio ${theme}`} key={index}>
                          <div className="img-cover-prot">
                            <img
                              src={portfolio.imageURL || ""}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">{portfolio.title}</h5>
                            <span className="card-text">
                              {portfolio.description}
                            </span>
                            <div className="mt-1 d-flex justify-content-between">
                              <a
                                className="a-link"
                                target="_blank"
                                href={portfolio.linkURL}
                              >
                                ver
                              </a>
                            </div>
                          </div>
                        </div>
                        </div>
                      )
                    )}
                  </Slider>
                </div>  :null}
                 
                {certificationsLength !== 0 ?  <div className="mb-5 mt-5">
                  <h4 className="mb-3">Certificado</h4>
                  <Slider {...settings}>                   
                    {userData?.certifications?.map(
                      (certification: Certification, index: number) => (
                        <div className="p-1">
                          <div
                          className={`card border card-certifications  ${theme}`}
                          key={index}
                        >
                          <iframe
                            // src={`${certification.pdfURL}`}
                            src="https://drive.google.com/file/d/1X_LZL2vTEg6psuSKDCMEYYBSIENKOYh7/view"
                            className="card-img-top"
                          ></iframe>
                          <div className="card-body">
                            <h5 className="card-title">
                              {certification.title}
                            </h5>
                            <span className="card-text">
                              {certification.description}
                            </span>
                            <div className="mt-1 d-flex justify-content-between">
                              <a
                                className="a-link"
                                target="_blank"
                                href={certification.pdfURL}
                              >
                                ver
                              </a>
                            </div>
                          </div>
                        </div>
                        </div>
                      )
                    )}
                  </Slider>
                </div>  : null}

                {/* <Rodape /> */}
              </div>
            </div>
          </div>
          
        </section>
        <ToastContainer />
      </main>
      <Modal
        size="lg"
        className="modal-video"
        show={showVideo}
        onHide={handleCloseVideo}
      >
        <Modal.Header className={`${theme}`} closeButton></Modal.Header>
        <Iframe
          url={
            userData && userData.introductionVideoURL
              ? userData.introductionVideoURL
              : "About não disponível"
          } // Link do vídeo do YouTube (certifique-se de usar o link correto)
          height="350px"
          display="block"
          position="relative"
          className="linkYoutube"
        />
      </Modal>

      <Modal show={showSendWorkRequest} onHide={handleCloseSendWorkRequest}>
        <Modal.Header closeButton className={`border-0 ${theme}`}>
          <h5>Solicitar Serviço</h5>
        </Modal.Header>
        <Modal.Body className={`pt-0 ${theme}`}>
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Evita o comportamento padrão de recarregar a página
              sendWorkRequest(userData.uid, titulo, valor, descricao);
            }}
          >
            <div className="container p-0">
              <div className="row">
                <div className="col-12 mb-4">
                  <InputForm
                    title={"Título do Trabalho"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineLightBulb />}
                    value={titulo}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setTitulo(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
                <div className="col-12 mb-4">
                  <InputForm
                    title={"Remuneração"}
                    type={"number"}
                    placeholder={""}
                    icon={<HiOutlineFire />}
                    value={valor}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setValor(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
                <div className="col-12 mb-3">
                  <InputFormTextArea
                    title={"Descrição do Trabalho"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineNewspaper />}
                    value={descricao}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setDescricao(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
              </div>
            </div>
            <button
              disabled={!btnShowSendWorkRequest}
              type="submit"
              className="btn btn-primary"
            >
              Enviar Solicitação
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer className={`border-0 ${theme}`}>
          
        </Modal.Footer>
      </Modal>
    </>
  );

  // (
  //   <div>
  //     <h2>Dados do Usuário</h2>
  //     <p>Email: {userData.email}</p>
  //     {users?.isActive !== false ? (
  //       <button onClick={() => sendWorkRequest(userData.uid)}>
  //         Enviar pedido de serviço
  //       </button>
  //     ) : null}
  //   </div>
  // );
};

export default UserDetails;
