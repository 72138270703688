import React, { useEffect, useState } from 'react';
import './style.scss';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../services/firebaseConfig';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { HiOutlineLocationMarker, HiOutlinePhone, HiOutlineUser } from 'react-icons/hi';

export default function AlertUser() {
    const [userData, setUserData] = useState<any>({});
    const [user] = useAuthState(auth);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const uid = auth.currentUser?.uid;
    const db = getFirestore();
    const [selectedProvincia, setSelectedProvincia] = useState("");
    const [selectedMunicipio, setSelectedMunicipio] = useState("");


    interface SelectLocation {
        selectedMunicipio: any;
        selectedProvincia: any;
    }

    interface Provincia {
        nome: any;
        municipios: any[];
    }

    const provincias: Provincia[] = [
        {
            nome: "Bengo",
            municipios: [
                "Ambriz",
                "Bula Atumba",
                "Dande",
                "Dembos",
                "Nambuangongo",
                "Pango Aluquém",
            ],
        },
        {
            nome: "Benguela",
            municipios: [
                "Baía Farta",
                "Balombo",
                "Benguela",
                "Bocoio",
                "Caimbambo",
                "Catumbela",
                "Chongoroi",
                "Cubal",
                "Ganda",
                "Lobito",
            ],
        },
        {
            nome: "Bié",
            municipios: [
                "Andulo",
                "Bailundo",
                "Camacupa",
                "Catabola",
                "Chinguar",
                "Chitembo",
                "Cuemba",
                "Cunhinga",
                "Cuito",
                "Nharea",
                "Nhârea",
            ],
        },
        {
            nome: "Cabinda",
            municipios: ["Belize", "Buco-Zau", "Cabinda", "Cacongo"],
        },
        {
            nome: "Cuando Cubango",
            municipios: [
                "Calai",
                "Cuangar",
                "Cuchi",
                "Cuito Cuanavale",
                "Dirico",
                "Longa",
                "Mavinga",
                "Menongue",
                "Nancova",
                "Rivungo",
            ],
        },
        {
            nome: "Cuanza Norte",
            municipios: [
                "Ambaca",
                "Bolongongo",
                "Cambambe",
                "Cazengo",
                "Golungo Alto",
                "Gonguembo",
                "Lucala",
                "Quiculungo",
                "Samba Cajú",
            ],
        },
        {
            nome: "Cuanza Sul",
            municipios: [
                "Amboim",
                "Cela",
                "Conda",
                "Ebo",
                "Libolo",
                "Mussende",
                "Porto Amboim",
                "Quibala",
                "Quilenda",
                "Seles",
            ],
        },
        {
            nome: "Cunene",
            municipios: [
                "Cahama",
                "Curoca",
                "Cuanhama",
                "Cuvelai",
                "Namacunde",
                "Ombadja",
            ],
        },
        {
            nome: "Huambo",
            municipios: [
                "Bailundo",
                "Cachiungo",
                "Caála",
                "Chicala-Cholohanga",
                "Chinhama",
                "Ecunha",
                "Huambo",
                "Londuimbali",
                "Longonjo",
                "Mungo",
                "Tchicala-Tcholohanga",
                "Ukuma",
            ],
        },
        {
            nome: "Huíla",
            municipios: [
                "Caconda",
                "Cacula",
                "Caluquembe",
                "Chibia",
                "Chicomba",
                "Chipindo",
                "Cuvango",
                "Gambos",
                "Humpata",
                "Jamba",
                "Lubango",
                "Matala",
                "Quilengues",
            ],
        },
        {
            nome: "Luanda",
            municipios: [
                "Belas",
                "Cacuaco",
                "Cazenga",
                "Icolo e Bengo",
                "Quiçama",
                "Luanda",
                "Talatona",
                "Viana",
            ],
        },
        {
            nome: "Lunda Norte",
            municipios: [
                "Cambulo",
                "Capenda Camulemba",
                "Caungula",
                "Chitato",
                "Cuango",
                "Cuílo",
                "Lubalo",
                "Lucapa",
                "Xá-Muteba",
            ],
        },
        {
            nome: "Lunda Sul",
            municipios: ["Cacolo", "Dala", "Muconda", "Saurimo"],
        },
        {
            nome: "Malanje",
            municipios: [
                "Calandula",
                "Cambundi-Catembo",
                "Cangandala",
                "Caombo",
                "Cunda-Dia-Baze",
                "Luquembo",
                "Malanje",
                "Marimba",
                "Massango",
                "Mucari",
                "Quela",
            ],
        },
        {
            nome: "Moxico",
            municipios: [
                "Alto Zambeze",
                "Bundas",
                "Cameia",
                "Camanongue",
                "Léua",
                "Luacano",
                "Luau",
                "Luchazes",
                "Luena",
                "Luimbale",
                "Lutembo",
            ],
        },
        {
            nome: "Namibe",
            municipios: ["Bibala", "Camucuio", "Moçâmedes", "Tômbwa", "Virei"],
        },
        {
            nome: "Uíge",
            municipios: [
                "Ambuila",
                "Bembe",
                "Buengas",
                "Bungo",
                "Damba",
                "Maquela do Zombo",
                "Mucaba",
                "Negage",
                "Puri",
                "Quitexe",
                "Quimbele",
                "Sanza Pombo",
                "Songo",
                "Uíge",
                "Zombo",
            ],
        },
        {
            nome: "Zaire",
            municipios: [
                "Cuimba",
                "M'banza Congo",
                "N'zeto",
                "Noqui",
                "Soyo",
                "Tomboco",
            ],
        },
    ];

    useEffect(() => {
        const getUserData = async () => {
            if (uid) {
                try {
                    const userDoc = doc(db, 'users', uid);
                    const docSnapshot = await getDoc(userDoc);
                    if (docSnapshot.exists()) {
                        const userData = docSnapshot.data();
                        setUserData(userData);

                        const missingInfo = !userData.displayName || !userData.phoneNumber || !userData.location?.selectedProvincia || !userData.location?.selectedMunicipio;
                        setIsModalOpen(missingInfo);
                    } else {
                        setIsModalOpen(true);
                    }
                } catch (error) {
                    console.error('Erro ao obter dados do usuário:', error);
                }
            }
        };

        getUserData();
    }, [uid, db]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleSave = async () => {
        if (uid) {
            try {
                const userDoc = doc(db, 'users', uid);
                await updateDoc(userDoc, {
                    ...userData,
                    location: {
                        selectedProvincia,
                        selectedMunicipio
                    }
                });
                setIsModalOpen(false);
            } catch (error) {
                console.error('Erro ao atualizar dados do usuário:', error);
            }
        }
    };

    const handleProvinciaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const provincia = e.target.value;
        setSelectedProvincia(provincia);
        setSelectedMunicipio("");
        setUserData({
            ...userData,
            location: {
                ...userData.location,
                selectedProvincia: provincia,
                selectedMunicipio: "",
            },
        });
    };

    const handleMunicipioChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const municipio = e.target.value;
        setSelectedMunicipio(municipio);
        setUserData({
            ...userData,
            location: {
                ...userData.location,
                selectedMunicipio: municipio,
            },
        });
    };

    return (
        <main className='alertUser d-flex align-items-center'>
            {isModalOpen && (
                <div className="modalalert">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="modal-content">
                                    <div className="card-body">
                                        <div className='text-center'>
                                            <h4>Complete seu perfil</h4>
                                            <p>Por favor, forneça as seguintes informações:</p>
                                        </div>
                                        <div className="input-group border mb-3">
                                            <span className="input-group-text">
                                                <HiOutlineUser />
                                            </span>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="displayName"
                                                placeholder="Nome"
                                                value={userData?.displayName || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-group border mb-3">
                                            <span className="input-group-text">
                                                <HiOutlinePhone />
                                            </span>
                                            <input
                                                type="number"
                                                name="phoneNumber"
                                                className='form-control'
                                                placeholder="Número de Telefone"
                                                value={userData?.phoneNumber || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                       <div className="row">
                                        <div className="col-6">
                                        <div className="input-group border mb-3">
                                            <span className="input-group-text">
                                                <HiOutlineLocationMarker />
                                            </span>
                                            <select
                                                className="form-select form-select-modal"
                                                value={userData?.location?.selectedProvincia || selectedProvincia}
                                                onChange={handleProvinciaChange}
                                            >
                                                <option value="">Província</option>
                                                {provincias.map((provincia, index) => (
                                                    <option key={index} value={provincia.nome}>
                                                        {provincia.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>                                        
                                        </div>
                                        <div className="col-6">
                                        <div className="input-group border mb-3">
                                            <span className="input-group-text">
                                                <HiOutlineLocationMarker />
                                            </span>
                                            <select
                                                className="form-select form-select-modal"
                                                value={userData?.location?.selectedMunicipio || selectedMunicipio}
                                                onChange={handleMunicipioChange}
                                                disabled={!selectedProvincia}
                                            >
                                                <option value="">Município</option>
                                                {provincias
                                                    .find((provincia) => provincia.nome === selectedProvincia)
                                                    ?.municipios.map((municipio, index) => (
                                                        <option key={index} value={municipio}>
                                                            {municipio}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        </div>
                                       </div>
                                        <div className="d-grid">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleSave}
                                            >
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
}
