import React, {useEffect} from 'react';
import google from './../../asset/vector/google.svg'
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { auth } from '../../services/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, setDoc, doc, getDoc } from 'firebase/firestore';
import firebaseConfig from '../../key';
import { UserInfo } from '../../interface';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss'
export const Google: React.FC = () => {
  const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
  const handleSignIn = () => {
    signInWithGoogle();
  };

  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const usersCollection = collection(db, 'users');

  const checkUserExists = async (uid: string) => {
    const userDocRef = doc(usersCollection, uid);
    const userDocSnapshot = await getDoc(userDocRef);
    return userDocSnapshot.exists();
  };

  const addUserIfNotExists = async (newUserData: UserInfo) => {
    const userExists = await checkUserExists(newUserData.uid);
    if (userExists) {
    } else {
      try {
        const docRef = await setDoc(doc(usersCollection, newUserData.uid), newUserData);
      } catch (error) {
        console.error('Erro ao adicionar o usuário:', error);
      }
    }
  };

  if (user) {
    // Salva o ID do usuário no localStorage para uso posterior
    localStorage.setItem('user', JSON.stringify(user.user.uid));

    // Cria um objeto com os dados do usuário a serem enviados para a coleção
    const newUserData: UserInfo = {
      uid: user.user.uid,
      displayName: user.user.displayName,
      email: user.user.email,
      phoneNumber: user.user.phoneNumber,
      photoURL: user.user.photoURL,
      providerId: '',
      skills: [],
      status: '',
      location: '',
      utolaCode: '',
      verified: false,
      about: '',
      username: '',
      clients: [],
      coverPhotoURL: null,
      isActive: true
    };

    // Chama a função para adicionar o usuário à coleção no Firebase
    addUserIfNotExists(newUserData);
    toast.success('Login com o Google realizado com sucesso!');
    navigate('/dashboard');
  }

  if (error) {
    toast.error('Ocorreu um erro ao fazer login com o Google. Por favor, tente novamente.', {
      autoClose: 8000,
    });
  }

  return (
    <div className='input-group-google input-group'>
      <div className='input-group-text'><img src={google} alt="" /></div>
      <button className='form-control' onClick={handleSignIn}>Google</button>
      <ToastContainer />
    </div>
  );
}
