import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { auth } from "../../services/firebaseConfig";
import { initializeApp } from "firebase/app";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  query,
  getDocs,
  where,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import firebaseConfig from "../../key";
import { Post, Skill } from "../../interface";
import { TagsInput } from "react-tag-input-component";
import { InputForm, InputFormDateTime, InputFormTextArea } from "../../components/inputForm";
import {
  HiOutlineLightningBolt,
  HiOutlineNewspaper,
  HiOutlineClock,
  HiOutlineCalendar,
  HiOutlineLocationMarker,
  HiOutlineHand,
  HiOutlinePencilAlt,
} from "react-icons/hi";
import logo from "./../../asset/vector/logo.svg";
import "./style.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

export const ButtomCreateJob: React.FC = () => {
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const db = getFirestore();
  const uid = auth.currentUser?.uid;
  const [selected, setSelected] = useState([]);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const postsCollection = collection(db, "requests");
  const skillsCollection = collection(db, "skills");
  const [selectedProvincia, setSelectedProvincia] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [newPostData, setNewPostData] = useState<Post>({
    completedBy: false,
    id: "",
    title: "",
    description: "",
    user_id: user?.uid,
    date: "",
    time: "",
    location: { selectedProvincia, selectedMunicipio },
    value: 0,
    skill: [],
    publish_date: new Date().toLocaleDateString(),
    publish_time: new Date().toLocaleTimeString(),
  });
  interface SelectLocation {
    selectedMunicipio: any;
    selectedProvincia: any;
  }

  interface Provincia {
    nome: any;
    municipios: any[];
  }
  const provincias: Provincia[] = [
    {
      nome: "Bengo",
      municipios: [
        "Ambriz",
        "Bula Atumba",
        "Dande",
        "Dembos",
        "Nambuangongo",
        "Pango Aluquém",
      ],
    },
    {
      nome: "Benguela",
      municipios: [
        "Baía Farta",
        "Balombo",
        "Benguela",
        "Bocoio",
        "Caimbambo",
        "Catumbela",
        "Chongoroi",
        "Cubal",
        "Ganda",
        "Lobito",
      ],
    },
    {
      nome: "Bié",
      municipios: [
        "Andulo",
        "Bailundo",
        "Camacupa",
        "Catabola",
        "Chinguar",
        "Chitembo",
        "Cuemba",
        "Cunhinga",
        "Cuito",
        "Nharea",
        "Nhârea",
      ],
    },
    {
      nome: "Cabinda",
      municipios: ["Belize", "Buco-Zau", "Cabinda", "Cacongo"],
    },
    {
      nome: "Cuando Cubango",
      municipios: [
        "Calai",
        "Cuangar",
        "Cuchi",
        "Cuito Cuanavale",
        "Dirico",
        "Longa",
        "Mavinga",
        "Menongue",
        "Nancova",
        "Rivungo",
      ],
    },
    {
      nome: "Cuanza Norte",
      municipios: [
        "Ambaca",
        "Bolongongo",
        "Cambambe",
        "Cazengo",
        "Golungo Alto",
        "Gonguembo",
        "Lucala",
        "Quiculungo",
        "Samba Cajú",
      ],
    },
    {
      nome: "Cuanza Sul",
      municipios: [
        "Amboim",
        "Cela",
        "Conda",
        "Ebo",
        "Libolo",
        "Mussende",
        "Porto Amboim",
        "Quibala",
        "Quilenda",
        "Seles",
      ],
    },
    {
      nome: "Cunene",
      municipios: [
        "Cahama",
        "Curoca",
        "Cuanhama",
        "Cuvelai",
        "Namacunde",
        "Ombadja",
      ],
    },
    {
      nome: "Huambo",
      municipios: [
        "Bailundo",
        "Cachiungo",
        "Caála",
        "Chicala-Cholohanga",
        "Chinhama",
        "Ecunha",
        "Huambo",
        "Londuimbali",
        "Longonjo",
        "Mungo",
        "Tchicala-Tcholohanga",
        "Ukuma",
      ],
    },
    {
      nome: "Huíla",
      municipios: [
        "Caconda",
        "Cacula",
        "Caluquembe",
        "Chibia",
        "Chicomba",
        "Chipindo",
        "Cuvango",
        "Gambos",
        "Humpata",
        "Jamba",
        "Lubango",
        "Matala",
        "Quilengues",
      ],
    },
    {
      nome: "Luanda",
      municipios: [
        "Belas",
        "Cacuaco",
        "Cazenga",
        "Icolo e Bengo",
        "Quiçama",
        "Luanda",
        "Talatona",
        "Viana",
      ],
    },
    {
      nome: "Lunda Norte",
      municipios: [
        "Cambulo",
        "Capenda Camulemba",
        "Caungula",
        "Chitato",
        "Cuango",
        "Cuílo",
        "Lubalo",
        "Lucapa",
        "Xá-Muteba",
      ],
    },
    {
      nome: "Lunda Sul",
      municipios: ["Cacolo", "Dala", "Muconda", "Saurimo"],
    },
    {
      nome: "Malanje",
      municipios: [
        "Calandula",
        "Cambundi-Catembo",
        "Cangandala",
        "Caombo",
        "Cunda-Dia-Baze",
        "Luquembo",
        "Malanje",
        "Marimba",
        "Massango",
        "Mucari",
        "Quela",
      ],
    },
    {
      nome: "Moxico",
      municipios: [
        "Alto Zambeze",
        "Bundas",
        "Cameia",
        "Camanongue",
        "Léua",
        "Luacano",
        "Luau",
        "Luchazes",
        "Luena",
        "Luimbale",
        "Lutembo",
      ],
    },
    {
      nome: "Namibe",
      municipios: ["Bibala", "Camucuio", "Moçâmedes", "Tômbwa", "Virei"],
    },
    {
      nome: "Uíge",
      municipios: [
        "Ambuila",
        "Bembe",
        "Buengas",
        "Bungo",
        "Damba",
        "Maquela do Zombo",
        "Mucaba",
        "Negage",
        "Puri",
        "Quitexe",
        "Quimbele",
        "Sanza Pombo",
        "Songo",
        "Uíge",
        "Zombo",
      ],
    },
    {
      nome: "Zaire",
      municipios: [
        "Cuimba",
        "M'banza Congo",
        "N'zeto",
        "Noqui",
        "Soyo",
        "Tomboco",
      ],
    },
  ];
  const addPost = async (postData: Post) => {
    try {
      if (user?.uid) {
        // if (newPostData.title.trim() === '' || newPostData.description.trim() === '' || newPostData.date === '' || newPostData.time === '' || selectedProvincia === '' || selectedMunicipio === '' || newPostData.value <= 0 || newPostData.skill.length === 0) {
        //   toast.error("Preencha todos os campos corretamente.");
        //   return;
        // }

        if (postData.title.trim() === "") {
          toast.error("Digite o título do trabalho.");
          return;
        }

        if (postData.description.trim() === "") {
          toast.error("Digite a descrição do trabalho.");
          return;
        }

        if (postData.date === "") {
          toast.error("Selecione a data do trabalho.");
          return;
        }

        if (postData.time === "") {
          toast.error("Selecione a hora do trabalho.");
          return;
        }

        if (selectedProvincia === "") {
          toast.error("Selecione a província.");
          return;
        }

        if (selectedMunicipio === "") {
          toast.error("Selecione o município.");
          return;
        }

        if (postData.value <= 0) {
          toast.error("Digite um valor válido.");
          return;
        }

        if (postData.skill.length === 0) {
          toast.error("Selecione pelo menos uma habilidade.");
          return;
        }

        setIsButtonDisabled(true);
        postData.user_id = user.uid;
        const docRef = await addDoc(postsCollection, postData);
        console.log("Post adicionado com sucesso! ID do documento:", docRef.id);
        toast.success("Seu trabalho foi publicado com sucesso!");
        const postsCollectionId = doc(db, "requests", docRef.id);
        if(!postData.id){
          await updateDoc(postsCollectionId, {id: docRef.id });
          console.log("Campo 'id' atualizado com sucesso!");
        }
        setTimeout(() => {
          window.location.reload();
        }, 5500);
      } else {
        console.error("Erro ao adicionar o post: user_id é undefined");
      }
    } catch (error) {
      console.error("Erro ao adicionar o post:", error);
    }
  };

  const handleSkillChange = async (tags: string[]) => {
    const newSkills = tags.filter((tag) => !newPostData.skill.includes(tag));
    if (newSkills.length === 0) {
      return;
    }
    const skillQuery = query(skillsCollection, where("name", "in", newSkills));
    const querySnapshot = await getDocs(skillQuery);
    const skills = querySnapshot.docs.map((doc) => doc.data().name);

    setNewPostData((prevData) => ({
      ...prevData,
      skill: [...prevData.skill, ...skills],
    }));
  };

  const handleSkillRemove = (tag: string) => {
    const updatedSkills = newPostData.skill.filter((skill) => skill !== tag);
    setNewPostData((prevData) => ({
      ...prevData,
      skill: updatedSkills,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addPost(newPostData);
    // console.log(newPostData);
  };

  const [currentStep, setCurrentStep] = useState(0);
  function handleNext() {
    setCurrentStep((prevState) => prevState + 1);
  }
  const handlePrevious = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const steps = [
    {
      id: "PERSONAL",
      title: "Dados pessoais",
    },
    {
      id: "SHIPPING",
      title: "Endereço de entrega",
    },
  ];

  const searchTooltip = (props: any) => (
    <Tooltip id="notification-tooltip" {...props}>
      Compartilhe seu trabalho com o mundo!
    </Tooltip>
  );

  useEffect(() => {
    const getUserData = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            // console.log("Dados do usuário:", userData);
            setUserData(userData);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUserData();
  }, [uid, db]);
  useEffect(() => {
    setNewPostData((prevData) => ({
      ...prevData,
      location: { selectedProvincia, selectedMunicipio },
    }));
  }, [selectedProvincia, selectedMunicipio]);

  const handleProvinciaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProvincia(e.target.value);
    setSelectedMunicipio("");
  };
  const handleMunicipioChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMunicipio(e.target.value);
  };

  const [inputValue, setInputValue] = useState("");
  const [skillss, setSkillss] = useState<Skill[]>([]);
  const [suggestions, setSuggestions] = useState<Skill[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    // Carregue os dados do localStorage quando o componente for montado
    const storedSkills = localStorage.getItem("skills");
    if (storedSkills) {
      const parsedSkills = JSON.parse(storedSkills) as Skill[];
      setSkillss(parsedSkills);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (newValue === "") {
      setSuggestions([]);
      setInputValue("");
    } else {
      setInputValue(newValue);
      const newSuggestions = skillss
        .filter((skill) =>
          skill.name.toLowerCase().includes(newValue.toLowerCase())
        )
        .slice(0, 5); // Limita a 7 sugestões
      setSuggestions(newSuggestions);
    }
  };

  const handleTagAdd = (tag: string) => {
    const newTags = [...tags, tag];
    setTags(newTags);
    setInputValue(""); // Limpa o campo de entrada após adicionar um tag
  };
  const theme = localStorage.getItem('theme')
  return (
    <div className="buttomCreateJob">
      {userData?.isActive && (
        // <OverlayTrigger
        //   placement="left"
        //   delay={{ show: 250, hide: 400 }}
        //   overlay={searchTooltip}
        // >

        <>
          <button className="nav-link btn-displaypc" onClick={handleShow}>Anunciar Trabalho</button>
          <button className="btn btn-menu-bt btn-displayphone" onClick={handleShow}><HiOutlinePencilAlt /></button>

          {/* btn rodape */}

          <div className="position-fixed m-5 bottom-0 btn-displaypc end-0">
            <button type="button" onClick={handleShow} className=" btn-create-jobs p-0 d-flex justify-content-center align-items-center">
              <img src={logo} alt="" />
              {/* <span>Anunciar Trabalho</span> */}
            </button>
          </div>
        </>
        // </OverlayTrigger>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border-0"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton className={`border-0 pt-4  ${theme}`}>
            <h5 className="ms-2">
              <b>Anunciar um Novo Trabalho</b>
            </h5>
          </Modal.Header>
          <Modal.Body className={`${theme}`}>
            <div className="container">
              {steps[currentStep].id === "PERSONAL" && (
                <div className="row">
                  <div className="col-12 mb-4">
                    <InputForm
                      type="text"
                      placeholder=""
                      icon={<HiOutlineLightningBolt />}
                      value={newPostData.title}
                      onChange={(e: { target: { value: any } }) =>
                        setNewPostData((prevData) => ({
                          ...prevData,
                          title: e.target.value,
                        }))
                      }
                      rows={0}
                      title="Titulo do trabalho"
                      name={""}
                    />
                  </div>
                  <div className="col-12 mb-4 mt-3">
                    <InputFormTextArea
                      type="text"
                      placeholder=""
                      icon={<HiOutlineNewspaper />}
                      value={newPostData.description}
                      onChange={(e: { target: { value: any } }) =>
                        setNewPostData((prevData) => ({
                          ...prevData,
                          description: e.target.value,
                        }))
                      }
                      rows={5}
                      title="Descrição do trabalho"
                      name={""}
                    />
                  </div>
                  <div className="col-6 mb-4 mt-3">
                    <InputFormDateTime
                      type="time"
                      placeholder=""
                      icon={<HiOutlineClock />}
                      value={newPostData.time}
                      onChange={(e: { target: { value: any } }) =>
                        setNewPostData((prevData) => ({
                          ...prevData,
                          time: e.target.value,
                        }))
                      }
                      rows={0}
                      title="Hora"
                      name={""}
                    />
                  </div>
                  <div className="col-6 mb-4 mt-3">
                    <InputFormDateTime
                      type="date"
                      placeholder=""
                      icon={<HiOutlineCalendar />}
                      value={newPostData.date}
                      onChange={(e: { target: { value: any } }) =>
                        setNewPostData((prevData) => ({
                          ...prevData,
                          date: e.target.value,
                        }))
                      }
                      rows={0}
                      title="Dia"
                      name={""}
                    />
                  </div>
                </div>
              )}
              {steps[currentStep].id === "SHIPPING" && (
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="row">
                      <div className="col-6">
                        <div className="inputForm">
                          <div className="label">
                            <span>Provincia</span>
                          </div>
                          <div className="input-group">
                            <select
                              value={selectedProvincia}
                              onChange={handleProvinciaChange}
                              className="form-select"
                            >
                              <option value="">Selecione uma província</option>
                              {provincias.map((provincia, index) => (
                                <option key={index} value={provincia.nome}>
                                  {provincia.nome}
                                </option>
                              ))}
                            </select>
                            <span className="pe-2 ps-2">
                              <HiOutlineLocationMarker />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="inputForm">
                          <div className="label">
                            <span>Município</span>
                          </div>
                          <div className="input-group">
                            <select
                              className="form-select"
                              value={selectedMunicipio}
                              onChange={handleMunicipioChange}
                            >
                              <option value="">Selecione um município</option>
                              {provincias
                                .find(
                                  (provincia) =>
                                    provincia.nome === selectedProvincia
                                )
                                ?.municipios.map((municipio, index) => (
                                  <option key={index} value={municipio}>
                                    {municipio}
                                  </option>
                                ))}
                            </select>
                            <span className="pe-2 ps-2">
                              <HiOutlineLocationMarker />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-4 mt-3">
                    <InputForm
                      type="text"
                      placeholder="..."
                      icon={<HiOutlineHand />}
                      value={newPostData.value}
                      onChange={(e: { target: { value: any | number } }) =>
                        setNewPostData((prevData) => ({
                          ...prevData,
                          value: +e.target.value,
                        }))
                      }
                      rows={5}
                      title="Valor Remunerado pelo Trabalho"
                      name={""}
                    />
                  </div> 
                  <div className="col-12 mt-1">
                    <label htmlFor="">Requisitos de Habilidade</label>
                    <TagsInput
                      value={newPostData.skill}
                      onChange={handleSkillChange}
                      onRemoved={handleSkillRemove}
                      name="skills"
                      placeHolder="Enter skills"
                    />
                    <input
                      type="text"
                      value={inputValue}
                      className={`searchSkill mt-2 ${theme}`}
                      placeholder="Pesquisar por habilidades"
                      onChange={handleInputChange}
                    />

                    {suggestions.length > 0 ? (
                      <ul className="nav flex-column">
                        {suggestions.map((suggestion) => (
                          <li
                            className="nav-item-suggestion"
                            key={suggestion.id}
                            onClick={() => handleSkillChange([suggestion.name])} // Add the skill
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    ) : null}

                    {/* Render the selected skills 
                    <div className="mt-2">
                      {newPostData.skill.map((skill, index) => (
                        <span key={index}>
                          <span className="me-3">{skill}</span>
                        </span>
                      ))}
                    </div>*/}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className={`border-top ${theme}`}>
            {/* <button className="btn">Cancelar</button>  */}

            {currentStep < steps.length - 1 && (
              <button
                className="btn button-btn"
                type="button"
                onClick={handleNext}
              >
                Próximo
              </button>
            )}
            {currentStep === steps.length - 1 && (
              <>
                <button className="btn" type="button" onClick={handlePrevious}>
                  Voltar
                </button>
                <button
                  className="btn button-submit"
                  onClick={() => !isButtonDisabled}
                  disabled={isButtonDisabled}
                >
                  Enviar
                </button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer />
    </div>
  );
};
