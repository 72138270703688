import React, { useState, useEffect } from "react";
// import OwlCarousel from "react-owl-carousel";
import Slider from "react-slick";

// import { doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, storage } from "../../services/firebaseConfig";
import capa from "./../../asset/capa.png";
import userDefalt from "./../../asset/userDefalt.png";
import logo from "./../../asset/vector/logo.svg";
import "./style.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  HiOutlineLocationMarker,
  HiOutlineMap,
  HiOutlineNewspaper,
  HiOutlinePencil,
  HiOutlinePencilAlt,
  HiOutlinePhone,
  HiOutlinePhotograph,
  HiOutlinePlay,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineUser,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Iframe from "react-iframe";
import Rodape from "../../components/rodape";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  InputForm,
  InputFormFile,
  InputFormTextArea,
} from "../../components/inputForm";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { TagsInput } from "react-tag-input-component";
import { Skill } from "../../interface";
const Profile: React.FC = () => {
  // Autenticação e estado do usuário
  const [user] = useAuthState(auth);
  const uid = auth.currentUser?.uid;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const maxTags = 4;
  const handleCloseVideo = () => setShowVideo(false);
  const handleShowVideo = () => setShowVideo(true);

  const [showAddJob, setShowAddJob] = useState(false);

  const handleCloseAddJob = () => setShowAddJob(false);
  const handleShowAddJob = () => setShowAddJob(true);

  const [showAddCertificatio, setShowAddCertificatio] = useState(false);

  const handleCloseAddCertificatio = () => setShowAddCertificatio(false);
  const handleShowAddCertificatio = () => setShowAddCertificatio(true);

  const [showAvatar, setShowAvatar] = useState(false);

  const handleCloseAvatar = () => setShowAvatar(false);
  const handleShowAvatar = () => setShowAvatar(true);

  const [showCover, setShowCover] = useState(false);

  const handleCloseCover = () => setShowCover(false);
  const handleShowCover = () => setShowCover(true);

  const [showCardProfil, setShowCardProfil] = useState(false);

  const handleCloseCardProfil = () => setShowCardProfil(false);
  const handleShowCardProfil = () => setShowCardProfil(true);

  const [showAbout, setShowAbout] = useState(false);

  const handleCloseAbout = () => setShowAbout(false);
  const handleShowAbout = () => setShowAbout(true);

  // Estado para armazenar os dados do usuário
  const [userData, setUserData] = useState<any>(null);

  // Configuração do Firestore e navegação
  const db = getFirestore();
  const navigate = useNavigate();

  // Função para renderizar o tooltip
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Conta verificada
    </Tooltip>
  );

  // Efeito para obter os dados do usuário
  useEffect(() => {
    const getUserData = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            // console.log("Dados do usuário:", userData);
            setUserData(userData);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUserData();
  }, [uid, db]);

  // Função para fazer logout
  // const handleLogout = () => {
  //   auth.signOut();
  //   navigate("/login");
  // };

  // Função para deletar um certificado
  const handleDeleteCertification = async (index: number) => {
    toast.info("A acarregar");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const updatedCertifications = [...(userData?.certifications || [])];
          updatedCertifications.splice(index, 1);

          await updateDoc(userDocRef, {
            certifications: updatedCertifications,
          });
          toast.success("Certificado apagado com sucesso!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          console.log("Certification deleted successfully!");
        }
      }
    } catch (error) {
      console.error("Error deleting certification:", error);
    }
  };

  // Função para deletar um trabalho do portfólio
  const handleDeletePortfolio = async (index: number) => {
    toast.info("A acarregar");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const updatedPortfolio = [...(userData?.portfolio || [])];
          updatedPortfolio.splice(index, 1);

          await updateDoc(userDocRef, {
            portfolio: updatedPortfolio,
          });
          toast.success("Projecto apagado com sucesso!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          console.log("Portfolio item deleted successfully!");
        }
      }
    } catch (error) {
      console.error("Error deleting portfolio item:", error);
    }
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [progressPorcents, setProgressPorcents] = useState(0);

  // const uid = auth.currentUser?.uid;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const addJob = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    if (!uid || !file) {
      return;
    }

    try {
      // Upload da imagem para o armazenamento
      toast.info("Enviando");
      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressPorcents(progress);
        },
        (error) => {
          alert(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Atualiza o documento do usuário com o novo trabalho
            const userDocRef = doc(db, "users", uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              const newJob = {
                title: title,
                description: description,
                imageURL: downloadURL,
                linkURL: linkUrl,
              };
              const updatedJobs = [...(userData?.portfolio || []), newJob];

              await updateDoc(userDocRef, {
                portfolio: updatedJobs,
              });

              console.log("Job added successfully!");

              setTitle("");
              setDescription("");
              setFile(null);
              setProgressPorcents(0);
              toast.success("Trabalho enviado com sucesso");

              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          } catch (error) {
            console.error("Error adding job:", error);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Definição dos tipos de dados
  type Certification = {
    title: string;
    description: string;
    date: string;
    pdfURL: string;
  };

  type Portfolio = {
    linkURL: string | undefined;
    imageURL: string;
    title: string;
    description: string;
    date: string;
    pdfURL: string;
  };

  const MAX_DESCRIPTION_LENGTH = 150;
  const MAX_TITLE_LENGTH = 15;

  const MAX_DESCRIPTION_CET_LENGTH = 100;
  const MAX_TITLE_CET_LENGTH = 15;

  const handleDescriptionCertChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_DESCRIPTION_CET_LENGTH) {
      setDescription(newValue);
    }
  };
  const handleTitleCertChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_TITLE_CET_LENGTH) {
      setTitle(newValue);
    }
  };

  const handleDescriptionChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(newValue);
    }
  };
  const handleTitleChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_TITLE_LENGTH) {
      setTitle(newValue);
    }
  };
  useEffect(() => {
    handleDescriptionChange({
      target: {
        value: description, // Coloque o valor adequado aqui
      },
    });

    handleTitleChange({
      target: {
        value: title, // Coloque o valor adequado aqui
      },
    });
    handleDescriptionCertChange({
      target: {
        value: description, // Coloque o valor adequado aqui
      },
    });

    handleTitleCertChange({
      target: {
        value: title, // Coloque o valor adequado aqui
      },
    });
  }, []);

  const [certificationTitle, setCertificationTitle] = useState("");
  const [certificationDescription, setCertificationDescription] = useState("");
  const [certificationDate, setCertificationDate] = useState("");
  const [certificationPdfURL, setCertificationPdfURL] = useState("");
  const [aboutText, setAboutText] = useState("");
  const resetCertificationForm = () => {
    setCertificationTitle("");
    setCertificationDescription("");
    setCertificationDate("");
    setCertificationPdfURL("");
  };

  const handleAddCertification = async (e: React.FormEvent) => {
    setIsButtonDisabled(true);
    e.preventDefault();
    try {
      toast.info("Enviando");
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        const newCertification = {
          title: certificationTitle,
          description: certificationDescription,
          date: certificationDate,
          pdfURL: certificationPdfURL,
        };
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const updatedCertifications = [
            ...(userData?.certifications || []),
            newCertification,
          ];
          await updateDoc(userDocRef, {
            certifications: updatedCertifications,
          });
          console.log("Certification added successfully!");
          toast.success("Certificado enviado com sucesso");
          resetCertificationForm();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (error) {
      console.error("Error adding certification:", error);
    }
  };

  const [imgURL, setImgURL] = useState("");
  const [progressPorcent, setPorgessPorcent] = useState(0);

  const handleSubmitAvatar = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const fileInput = event.currentTarget.elements.namedItem(
      "fileInput"
    ) as HTMLInputElement | null;
    const file = fileInput?.files?.[0];
    if (!file) return;

    try {
      toast.info("Enviando foto de perfil. Aguarde por favor...");
      setIsButtonDisabled(true);

      const randomNumber = Math.floor(Math.random() * 10000); // Gere um número aleatório entre 0 e 9999
      const timestamp = new Date().getTime(); // Obtenha o carimbo de data/hora atual
      const randomFileName = `image_${timestamp}_${randomNumber}.jpg`; // Crie um nome de arquivo único

      const storageRef = ref(storage, `images/${randomFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPorgessPorcent(progress);
        },
        (error) => {
          alert(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Atualiza o campo "photoURL" do usuário na coleção "users"
            if (uid) {
              const userDocRef = doc(db, "users", uid);
              await updateDoc(userDocRef, { photoURL: downloadURL });

              setImgURL(downloadURL);
              toast.success("Sua foto de perfil foi atualizada com sucesso!", {
                autoClose: 5000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          } catch (error) {
            console.error("Error updating Photo URL:", error);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading profile photo:", error);
    }
  };
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    undefined
  );

  const handleFileChangepre = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const [imagePreviewCover, setImagePreviewCover] = useState<
    string | undefined
  >(undefined);

  const handleFileChangeCover = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreviewCover(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const [progressPorcentCover, setPorgessPorcentCover] = useState(0);

  const [coverURL, setCoverURL] = useState("");
  const handleSubmitcover = async (event: React.FormEvent<HTMLFormElement>) => {
    toast.info("Enviando foto de capa. Aguarde por favor...");
    setIsButtonDisabled(true);

    event.preventDefault();
    const fileInput = event.currentTarget.elements.namedItem(
      "fileInputCover"
    ) as HTMLInputElement | null;
    const file = fileInput?.files?.[0];
    if (!file) return;

    try {
      const randomNumber = Math.floor(Math.random() * 10000); // Gere um número aleatório entre 0 e 9999
      const timestamp = new Date().getTime(); // Obtenha o carimbo de data/hora atual
      const randomFileName = `image_${timestamp}_${randomNumber}.jpg`; // Crie um nome de arquivo único

      const storageRef = ref(storage, `images/${randomFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPorgessPorcentCover(progress);
        },
        (error) => {
          alert(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Atualiza o campo "coverPhotoURL" do usuário na coleção "users"
            if (uid) {
              const userDocRef = doc(db, "users", uid);
              await updateDoc(userDocRef, { coverPhotoURL: downloadURL });

              setCoverURL(downloadURL);
              toast.success("Sua foto de capa foi atualizada com sucesso!");
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          } catch (error) {
            console.error("Error updating Photo URL:", error);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading profile photo:", error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  const handleSwitchChange = async () => {
    toast.info("Mudando o estado...");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          isActive: !isActive,
        });
        setIsActive((prevIsActive) => !prevIsActive);
        toast.success("Estado atualizado com sucesso.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating isActive:", error);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            // console.log("Dados do usuário:", userData);
            setUserData(userData);
            setIsActive(userData.isActive);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUserData();
  }, [uid, db]);

  const [displayName, setDisplayName] = useState("");
  const [value, setValue] = useState("");
  const handleDisplayNameUpdate = async (e: React.FormEvent) => {
    toast.info("Atualizando nome...");
    e.preventDefault();
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          displayName: displayName,
        });
        toast.success("Alteração de nome concluída.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating Display Name:", error);
    }
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  // const [aboutText, setAboutText] = useState("");
  const [utolaCode, setUtolaCode] = useState("");
  const [introductionVideoURL, setIntroductionVideoURL] = useState("");

  const handlePhoneNumberUpdate = async (e: React.FormEvent) => {
    toast.info("Atualizando número...");
    e.preventDefault();
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          phoneNumber: phoneNumber,
        });
        toast.success("Número atualizado com sucesso");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating Phone Number:", error);
    }
  };
  const handleUtolaCodeUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Atualizando código Utola...");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          utolaCode: utolaCode,
        });
        toast.success("Código Utola atualizado com sucesso");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating Utola Code:", error);
    }
  };

  const handleValuepdate = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Atualizando código Utola...");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          value: value,
        });
        toast.success("Código Utola atualizado com sucesso");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating Utola Code:", error);
    }
  };

  interface SelectLocation {
    selectedMunicipio: any;
    selectedProvincia: any;
  }

  interface Provincia {
    nome: any;
    municipios: any[];
  }
  const provincias: Provincia[] = [
    {
      nome: "Bengo",
      municipios: [
        "Ambriz",
        "Bula Atumba",
        "Dande",
        "Dembos",
        "Nambuangongo",
        "Pango Aluquém",
      ],
    },
    {
      nome: "Benguela",
      municipios: [
        "Baía Farta",
        "Balombo",
        "Benguela",
        "Bocoio",
        "Caimbambo",
        "Catumbela",
        "Chongoroi",
        "Cubal",
        "Ganda",
        "Lobito",
      ],
    },
    {
      nome: "Bié",
      municipios: [
        "Andulo",
        "Bailundo",
        "Camacupa",
        "Catabola",
        "Chinguar",
        "Chitembo",
        "Cuemba",
        "Cunhinga",
        "Cuito",
        "Nharea",
        "Nhârea",
      ],
    },
    {
      nome: "Cabinda",
      municipios: ["Belize", "Buco-Zau", "Cabinda", "Cacongo"],
    },
    {
      nome: "Cuando Cubango",
      municipios: [
        "Calai",
        "Cuangar",
        "Cuchi",
        "Cuito Cuanavale",
        "Dirico",
        "Longa",
        "Mavinga",
        "Menongue",
        "Nancova",
        "Rivungo",
      ],
    },
    {
      nome: "Cuanza Norte",
      municipios: [
        "Ambaca",
        "Bolongongo",
        "Cambambe",
        "Cazengo",
        "Golungo Alto",
        "Gonguembo",
        "Lucala",
        "Quiculungo",
        "Samba Cajú",
      ],
    },
    {
      nome: "Cuanza Sul",
      municipios: [
        "Amboim",
        "Cela",
        "Conda",
        "Ebo",
        "Libolo",
        "Mussende",
        "Porto Amboim",
        "Quibala",
        "Quilenda",
        "Seles",
      ],
    },
    {
      nome: "Cunene",
      municipios: [
        "Cahama",
        "Curoca",
        "Cuanhama",
        "Cuvelai",
        "Namacunde",
        "Ombadja",
      ],
    },
    {
      nome: "Huambo",
      municipios: [
        "Bailundo",
        "Cachiungo",
        "Caála",
        "Chicala-Cholohanga",
        "Chinhama",
        "Ecunha",
        "Huambo",
        "Londuimbali",
        "Longonjo",
        "Mungo",
        "Tchicala-Tcholohanga",
        "Ukuma",
      ],
    },
    {
      nome: "Huíla",
      municipios: [
        "Caconda",
        "Cacula",
        "Caluquembe",
        "Chibia",
        "Chicomba",
        "Chipindo",
        "Cuvango",
        "Gambos",
        "Humpata",
        "Jamba",
        "Lubango",
        "Matala",
        "Quilengues",
      ],
    },
    {
      nome: "Luanda",
      municipios: [
        "Belas",
        "Cacuaco",
        "Cazenga",
        "Icolo e Bengo",
        "Quiçama",
        "Luanda",
        "Talatona",
        "Viana",
      ],
    },
    {
      nome: "Lunda Norte",
      municipios: [
        "Cambulo",
        "Capenda Camulemba",
        "Caungula",
        "Chitato",
        "Cuango",
        "Cuílo",
        "Lubalo",
        "Lucapa",
        "Xá-Muteba",
      ],
    },
    {
      nome: "Lunda Sul",
      municipios: ["Cacolo", "Dala", "Muconda", "Saurimo"],
    },
    {
      nome: "Malanje",
      municipios: [
        "Calandula",
        "Cambundi-Catembo",
        "Cangandala",
        "Caombo",
        "Cunda-Dia-Baze",
        "Luquembo",
        "Malanje",
        "Marimba",
        "Massango",
        "Mucari",
        "Quela",
      ],
    },
    {
      nome: "Moxico",
      municipios: [
        "Alto Zambeze",
        "Bundas",
        "Cameia",
        "Camanongue",
        "Léua",
        "Luacano",
        "Luau",
        "Luchazes",
        "Luena",
        "Luimbale",
        "Lutembo",
      ],
    },
    {
      nome: "Namibe",
      municipios: ["Bibala", "Camucuio", "Moçâmedes", "Tômbwa", "Virei"],
    },
    {
      nome: "Uíge",
      municipios: [
        "Ambuila",
        "Bembe",
        "Buengas",
        "Bungo",
        "Damba",
        "Maquela do Zombo",
        "Mucaba",
        "Negage",
        "Puri",
        "Quitexe",
        "Quimbele",
        "Sanza Pombo",
        "Songo",
        "Uíge",
        "Zombo",
      ],
    },
    {
      nome: "Zaire",
      municipios: [
        "Cuimba",
        "M'banza Congo",
        "N'zeto",
        "Noqui",
        "Soyo",
        "Tomboco",
      ],
    },
  ];

  const [selectedProvincia, setSelectedProvincia] = useState<string>("");
  const [selectedMunicipio, setSelectedMunicipio] = useState<string>("");

  const [skills, setSkills] = useState<any[]>([]);
  const skillsCollection = collection(db, "skills");

  const handleProvinciaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProvincia(e.target.value);
    setSelectedMunicipio("");
  };
  const handleMunicipioChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMunicipio(e.target.value);
  };

  const handleLocationUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Atualizando localização...");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          location: {
            selectedProvincia,
            selectedMunicipio,
          },
        });
        toast.success("Localização atualizada com sucesso");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating Location:", error);
    }
  };

  const handleSkillChange = async (tags: string[]) => {
    const newSkills = tags.filter((tag) => !skills.includes(tag));

    if (newSkills.length === 0) {
      return; // Skip the query if there are no new skill names
    }

    try {
      const skillQuery = query(
        skillsCollection,
        where("name", "in", newSkills)
      );
      const querySnapshot = await getDocs(skillQuery);
      const existingSkills = querySnapshot.docs.map((doc) => doc.data().name);

      setSkills((prevSkills) => [...prevSkills, ...existingSkills]);
    } catch (error) {
      console.error("Error retrieving skills:", error);
    }
  };

  const handleSkillRemove = (tag: string) => {
    const updatedSkills = skills.filter((skill) => skill !== tag);
    setSkills(updatedSkills);
  };

  const handleSkillsUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Atualizando Habilidades...");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          skills: skills,
        });
        toast.success("Habilidades atualizadas com sucesso");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating Photo URL and Skills:", error);
    }
  };

  const handleIntroductionVideoURLUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Atualizando URL do vídeo de introdução...");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          introductionVideoURL: introductionVideoURL,
        });
        toast.success("Vídeo de introdução atualizado com sucesso");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating Introduction Video URL:", error);
    }
  };

  const handleAboutUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Atualizando informações...");
    try {
      if (uid) {
        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          about: aboutText,
        });
        toast.success("Informações sobre você atualizadas");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating About:", error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    interface UserData {
      displayName: string;
      phoneNumber: string;
      utolaCode: string;
      introductionVideoURL: string;
      selectedProvincia: string;
      selectedMunicipio: string;
      value:number | any,
      skills: any[];
    }
    e.preventDefault();
    const updatedData: Partial<UserData> = {};
    // Chamar as funções de atualização para cada campo
    try {
      // await handleDisplayNameUpdate(e);
      if (displayName && displayName !== userData?.displayName) {
        updatedData.displayName = displayName;
        await handleDisplayNameUpdate(e);
      }

      if (phoneNumber && phoneNumber !== userData?.phoneNumber) {
        updatedData.phoneNumber = phoneNumber;
        await handlePhoneNumberUpdate(e);
      }

      if (utolaCode && utolaCode !== userData?.utolaCode) {
        updatedData.utolaCode = utolaCode;
        await handleUtolaCodeUpdate(e);
      }
      

      if (value && value !== userData?.value) {
        updatedData.value = value;
        await handleValuepdate(e);
      }
      if (
        introductionVideoURL &&
        introductionVideoURL !== userData?.introductionVideoURL
      ) {
        updatedData.introductionVideoURL = introductionVideoURL;
        await handleIntroductionVideoURLUpdate(e);
      }

      if (
        selectedProvincia &&
        selectedProvincia !== userData?.selectedProvincia
      ) {
        updatedData.selectedProvincia = selectedProvincia;
        await handleLocationUpdate(e);
      }
      if (
        selectedMunicipio &&
        selectedMunicipio !== userData?.selectedMunicipio
      ) {
        updatedData.selectedMunicipio = selectedMunicipio;
        await handleLocationUpdate(e);
      }
      await handleSkillsUpdate(e);
      // if (skills && skills !== userData?skills) {
      //   updatedData.skills = skills;
      //   await handleSkillsUpdate(e);
      // }

      // await handleSkillsUpdate(e)

      // Aqui você pode adicionar qualquer lógica adicional após a atualização bem-sucedida
      // Por exemplo, redirecionar o usuário ou mostrar uma mensagem de sucesso geral
      // toast.success("Informações atualizadas com sucesso!");
      handleCloseCardProfil(); // Fechar o modal, se necessário
    } catch (error) {
      console.error("Error updating user information:", error);
      // Tratar erros, mostrar mensagens de erro, etc.
    }
  };

  // const handleFormSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();

  //   // Chamar as funções de atualização para cada campo
  //   try {
  //     await handleDisplayNameUpdate(e);
  //     await handlePhoneNumberUpdate(e);
  //     await handleUtolaCodeUpdate(e);
  //     await handleIntroductionVideoURLUpdate(e);
  //     await handleLocationUpdate(e)
  //     await handleSkillsUpdate(e)

  //     // Aqui você pode adicionar qualquer lógica adicional após a atualização bem-sucedida
  //     // Por exemplo, redirecionar o usuário ou mostrar uma mensagem de sucesso geral
  //     toast.success("Informações atualizadas com sucesso!");
  //     handleCloseCardProfil(); // Fechar o modal, se necessário
  //   } catch (error) {
  //     console.error("Error updating user information:", error);
  //     // Tratar erros, mostrar mensagens de erro, etc.
  //   }
  // };

  const [inputValue, setInputValue] = useState("");
  const [skillss, setSkillss] = useState<Skill[]>([]);
  const [suggestions, setSuggestions] = useState<Skill[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    // Carregue os dados do localStorage quando o componente for montado
    const storedSkills = localStorage.getItem("skills");
    if (storedSkills) {
      const parsedSkills = JSON.parse(storedSkills) as Skill[];
      setSkillss(parsedSkills);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (newValue === "") {
      setSuggestions([]);
      setInputValue("");
    } else {
      setInputValue(newValue);
      const newSuggestions = skillss
        .filter((skill) =>
          skill.name.toLowerCase().includes(newValue.toLowerCase())
        )
        .slice(0, 5); // Limita a 7 sugestões
      setSuggestions(newSuggestions);
    }
  };

  const handleTagAdd = (tag: string) => {
    const newTags = [...tags, tag];
    setTags(newTags);
    setInputValue(""); // Limpa o campo de entrada após adicionar um tag
  };


  const portfolioLength = userData?.portfolio?.length || 0;
  const certificationsLength = userData?.certifications?.length || 0;


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(2, certificationsLength),
    slidesToScroll: Math.min(2, certificationsLength), // Rola a mesma quantidade de itens que são exibidos
  };

  const settingsJobs = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(2, portfolioLength),
    slidesToScroll: Math.min(2, portfolioLength), // Rola a mesma quantidade de itens que são exibidos
  };

  const theme = localStorage.getItem('theme')

  return (
    <>
      <main className={`profile ${theme}`}>
        <section>
          <div className="container pt-5 pg-isMobiled-profilr">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-xl-8 pb-5">
                <div className={`card card-profile cc border shadow-none mt-5 ${theme}`}>
                  <div
                    className="capa d-flex justify-content-end"
                    style={{
                      backgroundImage: `url(${userData?.coverPhotoURL || capa
                        })`,
                    }}
                  >
                    <div className="p-3">
                      <button onClick={handleShowCover}>
                        <HiOutlinePhotograph />
                      </button>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <div className="inforUser d-flex">
                      <div>
                        <div className="photoURL">
                          <img src={userData?.photoURL || userDefalt} alt="" />
                        </div>
                        <div className="btn-imgUplud">
                          <button onClick={handleShowAvatar}>
                            <HiOutlinePhotograph />
                          </button>
                        </div>
                      </div>
                      <div className="ms-3 divDDDisplay">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="d-flex">
                              <h4>
                                {userData?.displayName &&
                                  userData.displayName.length > 60
                                  ? `${userData.displayName.substring(
                                    0,
                                    20
                                  )}...`
                                  : userData?.displayName}
                              </h4>
                            </div>
                            {userData?.verified ? (
                              <div className="verified ms-3">
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <img src={logo} alt="" />
                                </OverlayTrigger>
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <div>
                              {userData &&
                                userData.introductionVideoURL !== undefined ? (
                                <>
                                  <button
                                    className="btn-play"
                                    onClick={handleShowVideo}
                                  >
                                    <HiOutlinePlay />
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="local">
                          <HiOutlineLocationMarker className="mr-2" />
                          {userData && userData.location.selectedProvincia
                            ? userData.location.selectedProvincia
                            : "Provincia não disponível"}{" "}
                          /
                          {userData && userData.location.selectedMunicipio
                            ? userData.location.selectedMunicipio
                            : "Município não disponível"}{" "}
                          {/* <span className="ms-3">
                            Utola:{" "}
                            {userData && userData.utolaCode
                              ? userData.utolaCode
                              : "Código Utola não disponível"}
                          </span> */}
                          <div className="mt-2">
                            Email:{" "}
                            {userData && userData.email
                              ? userData.email
                              : "Email não disponível"}{" "}
                            <br /> Tel:{" "}
                            {userData && userData.phoneNumber
                              ? userData.phoneNumber
                              : "Número de telefone não disponível"}
                          </div>
                        </div>
                        <div className="sk mt-3">
                          {Array.isArray(userData?.skills) &&
                            userData.skills.length > 0 ? (
                            userData.skills.map(
                              (skill: string, index: number) => (
                                <span
                                  className="badge pe-3 ps-3 me-2"
                                  key={index}
                                >
                                  {skill}
                                </span>
                              )
                            )
                          ) : (
                            <span>Nenhuma habilidade disponível</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 divDDisplay">
                      <div className="d-flex">
                        <div>
                          <h4>
                            {userData?.displayName &&
                              userData.displayName.length > 50
                              ? `${userData.displayName.substring(0, 20)}...`
                              : userData?.displayName}
                          </h4>
                        </div>
                        {userData?.verified ? (
                          <div className="verified ms-3">
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <img src={logo} alt="" />
                            </OverlayTrigger>
                          </div>
                        ) : null}
                      </div>
                      <div className="local">
                        {userData && userData.location.selectedProvincia
                          ? userData.location.selectedProvincia
                          : "Provincia não disponível"}{" "}
                        /
                        {userData && userData.location.selectedMunicipio
                          ? userData.location.selectedMunicipio
                          : "Município não disponível"}{" "}
                        <span className="ms-3">
                          Utola:{" "}
                          {userData && userData.utolaCode
                            ? userData.utolaCode
                            : "Código Utola não disponível"}
                        </span>
                      </div>
                      <div className="opacity-50 font-14">
                        Email:{" "}
                        {userData && userData.email
                          ? userData.email
                          : "Email não disponível"}{" "}
                        <br /> Tel:{" "}
                        {userData && userData.phoneNumber
                          ? userData.phoneNumber
                          : "Número de telefone não disponível"}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer pb-3 border-0 pt-0 d-flex justify-content-between">
                    <div>
                      <form>
                        <div className="form-check form-switch">
                          {Array.isArray(userData?.skills) && userData.skills.length !== 0 && (
                            <>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={isActive}
                                onChange={handleSwitchChange}
                              />
                              <label className="form-check-label">
                                Sou cliente
                              </label>
                            </>
                          )}

                        </div>
                      </form>
                    </div>
                    <div>
                      <button
                        className="btn-edit-card"
                        onClick={handleShowCardProfil}
                      >
                        <HiOutlinePencilAlt />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={`card divDDisplay card-about mt-4 border shadow-none card-profile  ${theme}`}>
                  <div className="card-header pb-0 d-flex justify-content-between">
                    <div>
                      <b>Hablidades</b>
                    </div>
                  </div>
                  <div className="card-body pt-1 sk pb-4">
                    {Array.isArray(userData?.skills) &&
                      userData.skills.length > 0 ? (
                      userData.skills.map((skill: string, index: number) => (
                        <span className="badge pe-3 ps-3 me-2 mt-1" key={index}>
                          {skill}
                        </span>
                      ))
                    ) : (
                      <span>Nenhuma habilidade disponível</span>
                    )}
                  </div>
                </div>

                <div className={`card card-about mt-4 border shadow-none card-profile ${theme}`}>
                  <div className="card-header pb-0 d-flex justify-content-between">
                    <div>
                      <b>Sobre</b>
                    </div>
                    <div>
                      <button className="btn-sobre" onClick={handleShowAbout}>
                        <HiOutlinePencil />
                      </button>
                    </div>
                  </div>
                  <div className="card-body pb-4">
                    {userData && userData.about
                      ? userData.about
                        .split("\n")
                        .map(
                          (
                            paragraph: any,
                            index: React.Key | null | undefined
                          ) => <p key={index}>{paragraph}</p>
                        )
                      : "Informações não disponíveis"}
                  </div>
                </div>

                {portfolioLength !== 0 ? (
                  <div className="mt-5 mb-5">
                    <div className="d-flex justify-content-between">
                      <h4 className="mb-3">Meus trabalhos</h4>
                      <div>
                        <button
                          className="addJob btn"
                          onClick={handleShowAddJob}
                        >
                          <HiOutlinePlus />
                        </button>
                      </div>
                    </div>

                    <Slider {...settingsJobs}>
                      {userData?.portfolio?.map(
                        (portfolio: Portfolio, index: number) => (
                          <div className="p-1">
                            <div
                              className={`card card-profile border card-portfolio ${theme}`}
                              key={index}
                            >
                              <div className="img-cover-prot">
                                <img
                                  src={portfolio.imageURL || ""}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="card-body">
                                <h5 className="card-title">
                                  {portfolio.title}
                                </h5>
                                <span className="card-text">
                                  {portfolio.description}
                                </span>
                                <div className="mt-1 d-flex justify-content-between">
                                  <a
                                    className="a-link"
                                    target="_blank"
                                    href={portfolio.linkURL}
                                  >
                                    ver
                                  </a>
                                  <div>
                                    <button
                                      className="HiOutlineTrash"
                                      onClick={() =>
                                        handleDeletePortfolio(index)
                                      }
                                    >
                                      <HiOutlineTrash />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </Slider>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary my-3 mx-0"
                    onClick={handleShowAddJob}
                  >
                    Adicionar Projetos
                  </button>
                )}

                {certificationsLength !== 0 ? (
                  <div className="mb-5 mt-5">
                    <div className="d-flex justify-content-between">
                      <h4 className="mb-3">Certificado</h4>
                      <div>
                        <button
                          className="addJob btn"
                          onClick={handleShowAddCertificatio}
                        >
                          <HiOutlinePlus />
                        </button>
                      </div>
                    </div>

                    <Slider {...settings}>
                      {userData?.certifications?.map(
                        (certification: Certification, index: number) => (
                          <div className="p-1">
                            <div
                              className={`card card-profile border card-certifications item-slider ${theme}`}
                              key={index}
                              style={{ marginRight: "110px" }}
                            >
                              {/* <iframe
                                // src={`${certification.pdfURL}`}
                                src={certification.pdfURL || ''}
                                className="card-img-top"
                              ></iframe> */}
                              <div className="card-body">
                                <h5 className="card-title">
                                  {certification.title}
                                </h5>
                                <span className="card-text">
                                  {certification.description}
                                </span>
                                <div className="mt-1 d-flex justify-content-between">
                                  <a
                                    className="a-link"
                                    target="_blank"
                                    href={certification.pdfURL}
                                  >
                                    ver
                                  </a>
                                  <div>
                                    <button
                                      className="HiOutlineTrash"
                                      onClick={() =>
                                        handleDeleteCertification(index)
                                      }
                                    >
                                      <HiOutlineTrash />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </Slider>
                  </div>
                ) : (
                  <button type="button" className="btn btn-primary my-3 ms-3" onClick={handleShowAddCertificatio}>
                    Adicionar Certificações
                  </button>
                )}

                {/* <Rodape /> */}
              </div>
            </div>
          </div>
        </section>
        <Modal
          size="lg"
          className="modal-video"
          show={showVideo}
          onHide={handleCloseVideo}
        >
          <Modal.Header closeButton></Modal.Header>
          <Iframe
            url={
              userData && userData.introductionVideoURL
                ? userData.introductionVideoURL
                : "Vídeo de introdução não disponível"
            } // Link do vídeo do YouTube (certifique-se de usar o link correto)
            height="350px"
            display="block"
            position="relative"
            className="linkYoutube"
          />
        </Modal>

        <Modal
          className={`modalForm`}
          show={showAddJob}
          onHide={handleCloseAddJob}
        >
          <Modal.Header closeButton className={`border-0 pb-0 ${theme}`}>
            <h5>Adicionar meus trabalhos</h5>
          </Modal.Header>
          <Modal.Body className={`pb-0 ${theme}`}>
            <form className="container p-0" onSubmit={addJob}>
              <div className="row">
                <div className="col-12 mb-3">
                  <InputForm
                    title={"Imagem"}
                    type={"file"}
                    placeholder={""}
                    icon={<HiOutlinePhotograph />}
                    value={undefined}
                    onChange={handleFileChange}
                    rows={0}
                    name={undefined}
                  />
                </div>
                <div className="col-6 mb-3">
                  <InputForm
                    title={"Titulo"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineNewspaper />}
                    value={title}
                    onChange={(e: { target: { value: any } }) => {
                      const newValue = e.target.value;
                      if (newValue.length <= MAX_TITLE_LENGTH) {
                        setTitle(newValue);
                      }
                    }}
                    rows={0}
                    name={title}
                  />
                  <div className="mt-2">
                    {title.length} / {MAX_TITLE_LENGTH}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <InputFormFile
                    title={"Url"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineNewspaper />}
                    value={linkUrl}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setLinkUrl(e.target.value)}
                    rows={0}
                    name={linkUrl}
                  />
                </div>
                <div className="col-12">
                  <InputFormTextArea
                    title={"Descrição"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineNewspaper />}
                    value={description}
                    onChange={(e: { target: { value: any } }) => {
                      const newValue = e.target.value;
                      if (newValue.length <= MAX_DESCRIPTION_LENGTH) {
                        setDescription(newValue);
                      }
                    }}
                    rows={0}
                    name={description}
                  />
                  <div className="mt-2">
                    {description.length} / {MAX_DESCRIPTION_LENGTH}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-4 mb-2"
                disabled={isButtonDisabled}
              >
                Adicionar trabalho
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer className={`${theme} border-0`}>
          </Modal.Footer>
        </Modal>

        <Modal
          className="modalForm"
          show={showAddCertificatio}
          onHide={handleCloseAddCertificatio}
        >
          <Modal.Header closeButton className={`border-0 pb-0 ${theme}`}>
            <h5>Adicionar minhas certificações</h5>
          </Modal.Header>
          <Modal.Body className={`pb-0 ${theme}`}>
            <form className="container p-0" onSubmit={handleAddCertification}>
              <div className="row">
                <div className="col-12 mb-3">
                  <InputForm
                    title={"Certificado de?"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlinePhotograph />}
                    value={certificationTitle}
                    onChange={(e: { target: { value: any } }) => {
                      const newValue = e.target.value;
                      if (newValue.length <= MAX_TITLE_CET_LENGTH) {
                        setCertificationTitle(newValue);
                      }
                    }}
                    rows={0}
                    name={certificationTitle}
                  />
                  <div className="mt-2">
                    {certificationTitle.length} / {MAX_TITLE_CET_LENGTH}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <InputForm
                    title={"Data de entrega"}
                    type={"date"}
                    placeholder={""}
                    icon={<HiOutlineNewspaper />}
                    value={certificationDate}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setCertificationDate(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
                <div className="col-6 mb-3">
                  <InputFormFile
                    title={"Url do PDF"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineNewspaper />}
                    value={certificationPdfURL}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setCertificationPdfURL(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
                <div className="col-12">
                  <InputFormTextArea
                    title={"Descrição"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineNewspaper />}
                    value={certificationDescription}
                    onChange={(e: { target: { value: any } }) => {
                      const newValue = e.target.value;
                      if (newValue.length <= MAX_DESCRIPTION_CET_LENGTH) {
                        setCertificationDescription(newValue);
                      }
                    }}
                    rows={0}
                    name={certificationDescription}
                  />
                  <div className="mt-2">
                    {certificationDescription.length} /{" "}
                    {MAX_DESCRIPTION_CET_LENGTH}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-4 mb-2"
                disabled={isButtonDisabled}
              >
                Adicionar trabalho
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer className={`border-0 ${theme}`}>
          </Modal.Footer>
        </Modal>

        <Modal size="sm" show={showAvatar} onHide={handleCloseAvatar}>
          <Modal.Header className={`pb-0 border-0 ${theme}`} closeButton>
            <h5>Alterar Minha Foto de Perfil</h5>
          </Modal.Header>
          <Modal.Body className={`pb-0 ${theme}`}>
            <div>
              <div className="d-flex justify-content-center">
                {imagePreview && (
                  <div className="image-preview">
                    <img src={imagePreview} alt="Preview" />
                  </div>
                )}
              </div>
              <div className="text-center mt-3 mb-3">
                {!imgURL && <>{progressPorcent}%</>}
              </div>
              <form onSubmit={handleSubmitAvatar} encType="multipart/form-data">
                {/* <InputForm
                  title={"Buscar foto de perfil"}
                  type={"file"}
                  placeholder={""}
                  icon={undefined}
                  value={undefined}
                  onChange={handleFileChangepre}
                  rows={0}
                  name={"fileInput"}
                /> */}
                {/* <input
                  type="file"
                  name="fileInput"
                  onChange={handleFileChangepre}
                /> */}
                <div className="d-flex ">
                  <div className="me-3">
                    <label className="btn btn-primary form-control custom-file-upload">
                      <input
                        type="file"
                        name="fileInput"
                        required
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChangepre}
                      />
                      <HiOutlinePhotograph />
                    </label>
                  </div>
                  <button
                    className="btn btn-primary fileInputCover"
                    disabled={isButtonDisabled}
                    type="submit"
                  >
                    Atualizar Foto de Perfil
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className={`border-0 ${theme}`}>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showCover}
          onHide={handleCloseCover}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className={`pb-2 border-0 ${theme}`} closeButton>
            <h5>Alterar Minha Imagem de Capa</h5>
          </Modal.Header>
          <div>
            {imagePreviewCover && (
              <div
                className="image-preview-cover"
                style={{
                  backgroundImage: `url(${imagePreviewCover})`,
                }}
              >
                {/* <img src={imagePreviewCover} alt="Preview" /> */}
              </div>
            )}
          </div>
          <Modal.Body className={`pb-0 ${theme}`}>
            <div className="text-center pb-3">
              {!coverURL && <>{progressPorcentCover}%</>}
            </div>
            <form onSubmit={handleSubmitcover} encType="multipart/form-data">
              {/* <InputForm
                title={"Buscar foto de Capa"}
                type={"file"}
                placeholder={""}
                icon={undefined}
                value={undefined}
                onChange={handleFileChangeCover}
                rows={0}
                name={"fileInputCover"}
              /> */}

              <div className="d-flex ">
                <div className="me-3">
                  <label className="btn btn-primary form-control custom-file-upload">
                    <input
                      type="file"
                      name="fileInputCover"
                      required
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChangeCover}
                    />
                    <HiOutlinePhotograph />
                  </label>
                </div>
                <button
                  className="btn btn-primary fileInputCover"
                  disabled={isButtonDisabled}
                  type="submit"
                >
                  Atualizar Foto de Capa
                </button>
              </div>

              {/* <input
                type="file"
                name="fileInputCover"
                onChange={handleFileChangeCover}
              /> */}
              {/* <button type="submit">Enviar</button> */}
            </form>
          </Modal.Body>
          <Modal.Footer className={`border-0 ${theme}`}>
          </Modal.Footer>
        </Modal>

        <Modal show={showCardProfil} onHide={handleCloseCardProfil}>
          <Modal.Header className={`pb-0 border-0 ${theme}`} closeButton>
            <h5>Editar Dados do Perfil</h5>
          </Modal.Header>
          <Modal.Body className={`pb-0 ${theme}`}>
            <div className="container p-0">
              <form onSubmit={handleFormSubmit} className="row">
                <div className="col-12 mb-4">
                  <InputForm
                    title={"Nome"}
                    type={""}
                    placeholder={""}
                    icon={<HiOutlineUser />}
                    value={displayName || userData?.displayName}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setDisplayName(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
                <div className="col-12 mb-4">
                  <InputForm
                    title={"Valor"}
                    type={""}
                    placeholder={""}
                    icon={<HiOutlineUser />}
                    value={value || userData?.value}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setValue(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
                <div className="col-12 mb-4">
                  <InputForm
                    title={"Número de Telefone"}
                    type={"number"}
                    placeholder={""}
                    icon={<HiOutlinePhone />}
                    value={phoneNumber || userData?.phoneNumber}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setPhoneNumber(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div>
                {/* <div className="col-6 mb-4">
                  <InputForm
                    title={"Código Utola"}
                    type={"text"}
                    placeholder={""}
                    icon={<HiOutlineMap />}
                    value={utolaCode || userData?.utolaCode}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setUtolaCode(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div> */}

                {/* <div className="col-12 mb-4">
                  <InputForm
                    title={"Link do Vídeo de Introdução (YouTube)"}
                    type={""}
                    placeholder={""}
                    icon={<HiOutlinePlay />}
                    value={
                      introductionVideoURL || userData?.introductionVideoURL
                    }
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setIntroductionVideoURL(e.target.value)}
                    rows={0}
                    name={undefined}
                  />
                </div> */}

                <div className="col-12 mb-4">
                  <div className="container p-0">
                    <div className="row">
                      <div className="col-6">
                        <div className="inputForm">
                          <div className="label">
                            <span>Província</span>
                          </div>
                          <div className="input-group">
                            <select
                              value={
                                selectedProvincia || userData?.selectedProvincia
                              }
                              onChange={handleProvinciaChange}
                              className="form-select border-0"
                            >
                              <option value="" className="form-control">
                                Selecione uma província
                              </option>
                              {provincias.map((provincia, index) => (
                                <option key={index} value={provincia.nome}>
                                  {provincia.nome}
                                </option>
                              ))}
                            </select>
                            <span className="pe-2 ps-2">
                              <div>
                                <HiOutlineLocationMarker />
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="inputForm">
                          <div className="label">
                            <span>Município</span>
                          </div>
                          <div className="input-group">
                            <select
                              value={
                                selectedMunicipio || userData?.selectedMunicipio
                              }
                              onChange={handleMunicipioChange}
                              className="form-select border-0"
                            >
                              <option value="">Selecione um município</option>
                              {provincias
                                .find(
                                  (provincia) =>
                                    provincia.nome === selectedProvincia
                                )
                                ?.municipios.map((municipio, index) => (
                                  <option key={index} value={municipio}>
                                    {municipio}
                                  </option>
                                ))}
                            </select>
                            <span className="pe-2 ps-2">
                              <div>
                                <HiOutlineLocationMarker />
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <h6 className="mb-2">Habilidades</h6>
                  <TagsInput
                    value={skills || userData?.skills}
                    onChange={handleSkillChange}
                    onRemoved={handleSkillRemove}
                    name="skills"
                    placeHolder="Selecione habilidades"
                  />

                  <input
                    type="text"
                    value={inputValue}
                    className={`searchSkill mt-2 ${theme}`}
                    placeholder="Pesquisar habilidades"
                    onChange={handleInputChange}
                  />

                  {suggestions.length > 0 ? (
                    <ul className={`nav flex-column ${theme}`}>
                      {suggestions.map((suggestion) => (
                        <li
                          className="nav-item-suggestion"
                          key={suggestion.id}
                          onClick={() => handleSkillChange([suggestion.name])} // Add the skill
                        >
                          {suggestion.name}
                        </li>
                      ))}
                    </ul>
                  ) : null}

                  {/* <div className="mt-2">
                    {skills.map((skill, index) => (
                      <span key={index} className="me-3">
                        {skill}
                      </span>
                    ))}
                  </div> */}
                </div>

                <div className="col-12 mt-2">
                  <button className="btn btn-primary" type="submit">
                    Salvar Alterações
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className={`border-0 ${theme}`}>
          </Modal.Footer>
        </Modal>

        <Modal show={showAbout} onHide={handleCloseAbout}>
          <Modal.Header closeButton className={`border-0 pb-0 ${theme}`}>
            <h5>Editar Informações Sobre Mim</h5>
          </Modal.Header>
          <Modal.Body className={`pb-0 ${theme}`}>
            <form onSubmit={handleAboutUpdate}>
              <textarea
                value={aboutText}
                onChange={(e) => setAboutText(e.target.value)}
                placeholder="Escreva sobre você aqui..."
                className={`form-control  form-controltextarea  border-0 ${theme}`}
              />

              <button className="btn btn-primary" type="submit">
                Salvar Alterações
              </button>
              {/* <button type="submit"></button> */}
            </form>
          </Modal.Body>
          <Modal.Footer className={`border-0 ${theme}`}>
          </Modal.Footer>
        </Modal>
      </main>
      <ToastContainer />
    </>
  );
};

export default Profile;
