import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import './style.scss'
import "owl.carousel/dist/assets/owl.theme.default.css";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";

import firebaseConfig from "../../key";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import Rodape from "../../components/rodape";
import {
  CardRequests,
  CardRequestsService,
} from "../../components/cardRequests";
import { getAuth } from "firebase/auth";
import { UserInfo, Notification, Job } from "../../interface";
import { CardProfessionals } from "../../components/cardProfessionals";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebaseConfig";
const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const db = getFirestore(firebaseApp);
const ServiceRequest: React.FC = () => {
  const [serviceData, setServiceData] = useState<any>(null);
  const { id } = useParams();
  const uid = id ?? "";
  const [acceptedNotifications, setAcceptedNotifications] = useState<string[]>(
    []
  );
  const auth = getAuth(firebaseApp);
  const usersCollection = collection(db, "users");
  const currentUser = auth.currentUser;
  const [users, setUsers] = useState<UserInfo[]>([]);
  const horaAtual = new Date().toLocaleTimeString();
  const dataAtual = new Date().toLocaleDateString();
  const uidUser = serviceData?.user_id;
  const [userData, setUserData] = useState<UserInfo | null>(null);

  const [userServe, setUserServe] = useState<any>(null);
  const [serviceDocId, setServiceDocId] = useState("");
  const [user] = useAuthState(auth);
  const userId = user?.uid;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      if (userId) {
        // Use userId aqui em vez de uid
        try {
          const userDoc = doc(usersCollection, userId);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userServe = docSnapshot.data();
            // console.log("Dados do usuário:", userServe);
            setUserServe(userServe);
          } else {
            console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUserData();
  }, [userId]); // Use userId aqui em vez de uid

  useEffect(() => {
    // console.log("Dados do usuário atualizados:", userServe);
  }, [userServe]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const userDocRef = doc(usersCollection, uidUser);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = {
            ...userDocSnapshot.data(),
            id: userDocSnapshot.id,
          } as unknown as UserInfo;
          setUserData(userData);
        } else {
          // console.log("User document not found.");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, [uidUser, usersCollection]);

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const userDocRef = doc(firestore, "servicerequest", uid);
        const serviceDocSnap = await getDoc(userDocRef);

        if (serviceDocSnap.exists()) {
          const serviceData = serviceDocSnap.data(); // Obtenha os dados do documento
          const id = serviceDocSnap.id;
          setServiceDocId(id);
          setServiceData(serviceData); // Configure os dados no estado
        } else {
          // console.log("Documento não encontrado");
        }
      } catch (error) {
        console.error("Erro ao buscar os dados do serviço:", error);
      }
    };

    fetchServiceData();
  }, [uid]);

  // if (!serviceData) {
  //   return <p>Carregando dados do usuário...</p>;
  // }

  const acceptServiceRequest = async (serviceData: {
    userRequest: any;
    skill: any;
    description: any;
    userId: any;
    requesterId: any;
    title: any;
    value: any;
  }) => {
    setIsButtonDisabled(true);
    try {
      // Crie um novo objeto de Job com os dados do serviço aceito
      const newJobData = {
        jobId: "",
        description: serviceData.description || "",
        location: "",
        publishDate: dataAtual,
        publishTime: horaAtual,
        skillsRequired: serviceData.skill,
        createdBy: uidUser || "",
        createdDate: dataAtual,
        isCompleted: false,
        completedBy: serviceData.userRequest,
        completedDate: dataAtual,
        proposedBy: [],
        acceptedProposal: serviceData.userRequest,
        acceptedDate: dataAtual,
        isCreatedByUser: false,
        notificationId: serviceData.requesterId || "",
        uid: "",
        title: serviceData.title || "",
        value: serviceData.value || 0,
        requestService: true,
        requestId: serviceDocId,
      };

      // Adicione o novo trabalho à coleção "jobs"
      const newJobRef = doc(collection(db, "jobs"));
      await setDoc(newJobRef, newJobData);

      toast.success("Serviço aceito com sucesso!");

      setTimeout(() => {
        window.location.reload();
      }, 3000);

      const notification: Notification = {
        userId: uidUser,
        message: `Aceitou sua solicitação`,
        timestamp: `${dataAtual} - ${horaAtual}`,
        isRead: false,
        proposalId: "",
        jobId: "",
        requesterName: userServe?.displayName,
        requesterId: "",
        requesterPhoto: userServe?.photoURL,
        requesterPhoneNumber: "",
        notificationId: "",
        requestService: true, // Defina como true para indicar que é uma notificação de solicitação de serviço
        requestServiceID: serviceDocId, // Inclua o ID do serviço na notificação
      };

      const notificationsCollection = collection(db, "notifications");
      await addDoc(notificationsCollection, notification);
    } catch (error) {
      console.error("Erro ao aceitar o serviço:", error);
    }

    const jobRef = doc(db, "servicerequest", serviceDocId);
    await updateDoc(jobRef, {
      serviceActive: true,
    });
  };


  const deleteServiceRequeste = async (serviceDocId: string) => {
    try {
      const servicerequest = doc(db, "servicerequest", serviceDocId);
      await deleteDoc(servicerequest);
      toast.info("Solicitação rejeitada com sucesso.");

      const notificationNotJob: Notification = {
        userId: uidUser,
        message: `Rejeitou sua solicitação`,
        timestamp: `${dataAtual} - ${horaAtual}`,
        isRead: false,
        proposalId: "",
        jobId: "",
        requesterName: userServe?.displayName,
        requesterId: "",
        requesterPhoto: userServe?.photoURL,
        notificationId: serviceDocId, // Defina a ID da notificação como serviceDocId
        requestService: true,
        requestServiceID: serviceDocId,
      };

      const notificationsCollection = collection(db, "notifications" );
      await addDoc(notificationsCollection, notificationNotJob);

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);

    } catch (error) {
      console.error("Erro ao remover o documento:", error);
    }
  };
  const theme = localStorage.getItem('theme')
  return (
    <main className={`pt-5 pb-5 pg-isMobiled serviceRequestTela mt-5 w-100 hm ${theme}`}>
      <section>
        <div className="container pt-2 pb-3">
          <div className="row justify-content-center">
            <div className="col-xl-7 pb-5">
              <h5 className="mb-4 text-center-d">Solicitação de serviço</h5>
              {uidUser ? (
                <>
                  {/* serviceData.userRequest === userServe.uid */}
                  {uidUser === userServe.uid ||
                  serviceData.userRequest === userServe.uid ? (
                    <>
                      <CardProfessionals
                          uid={uidUser || ""}
                          displayName={userData?.displayName || ""}
                          phoneNumber={userData?.phoneNumber || ""}
                          photoURL={userData?.photoURL || ""}
                          skills={userData?.skills || []} // Use an empty array as a default
                          location={""}
                          verified={userData?.verified || false} // Use false as a default
                          about={userData?.about || ""} value={userData?.value || ""}                      />
                      <CardRequestsService
                        title={serviceData.title || ""}
                        description={serviceData.description || ""}
                        date={serviceData.date || ""}
                        location={serviceData.location || ""}
                        details={""}
                        value={serviceData.value || 0} // Use 0 as a default
                        skill={serviceData.skill || ""}
                        publish_date={serviceData.publish_date || ""}
                        publish_time={serviceData.publish_time || ""}
                        link={""}
                        btns={undefined}
                      />

                      {uidUser === userServe.uid ? null : (
                        <div className="mt-4 text-center">
                          <button
                            type="button"
                            disabled={isButtonDisabled}
                            className={`btn btn-primary ${
                              serviceData.serviceActive ? "disabled" : ""
                            }`}
                            onClick={() =>
                              serviceData.serviceActive
                                ? null
                                : acceptServiceRequest(serviceData)
                            }
                          >
                            {serviceData.serviceActive
                              ? "Trabalho em andamento"
                              : "Aceitar solicitação"}
                          </button>
                          {!serviceData.serviceActive ? (
                            <button
                              type="button"
                              className="btn btn-dark ms-3"
                              onClick={() =>
                                deleteServiceRequeste(serviceDocId)
                              }
                              disabled={acceptedNotifications.includes(
                                serviceDocId
                              )}
                            >
                              Recusar Solicitação
                            </button>
                          ) : null}
                        </div>
                      )}
                    </>
                  ) : (
                    <p>Este trabalho não foi solicitado por você.</p>
                  )}
                </>
              ) : null}

              {/* <Rodape /> */}
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
};
export default ServiceRequest;
function uuidv4(): string {
  throw new Error("Function not implemented.");
}
