import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  getDocs,
  where,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { Job, Post } from "../../interface";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { auth } from "../../services/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  CardRequests,
  CardRequestsJob,
  CardRequestsJobs,
} from "../../components/cardRequests";
import {
  HiBookmark,
  HiOutlineArrowNarrowRight,
  HiOutlineTrash,
} from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import Rodape from "../../components/rodape";
import { Link } from "react-router-dom";
const Jobs: React.FC = () => {
  const [createdJobs, setCreatedJobs] = useState<Job[]>([]);
  const [inProgressJobs, setInProgressJobs] = useState<Job[]>([]);
  const [completedJobs, setCompletedJobs] = useState<Job[]>([]);
  const db = getFirestore();
  const [user] = useAuthState(auth);
  const userId = user?.uid;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  useEffect(() => {
    const qCreatedJobs = query(
      collection(db, "requests"),
      where("user_id", "==", userId)
    );
    const qInProgressJobsCreatedBy = query(
      collection(db, "jobs"),
      where("isCompleted", "==", false),
      where("createdBy", "==", userId)
    );
    const qInProgressJobsCompletedBy = query(
      collection(db, "jobs"),
      where("isCompleted", "==", false),
      where("completedBy", "==", userId)
    );
    const qCompletedJobs = query(
      collection(db, "jobs"),
      where("isCompleted", "==", true)
    );

    const unsubscribeCreatedJobs = onSnapshot(qCreatedJobs, async () => {
      const createdJobsSnapshot = await getDocs(qCreatedJobs);
      const createdJobsData = createdJobsSnapshot.docs.map((doc) => {
        const data = doc.data() as Job;
        const uid = doc.id; // Acesse o UID do documento aqui
        return { ...data, uid };
      });
      setCreatedJobs(createdJobsData);
    });

    const unsubscribeInProgressJobsCreatedBy = onSnapshot(
      qInProgressJobsCreatedBy,
      async () => {
        const inProgressJobsSnapshot = await getDocs(qInProgressJobsCreatedBy);
        const inProgressJobsData = inProgressJobsSnapshot.docs.map(
          (doc) => doc.data() as Job
        );
        setInProgressJobs((prevJobs) => [...prevJobs, ...inProgressJobsData]);
      }
    );

    const unsubscribeInProgressJobsCompletedBy = onSnapshot(
      qInProgressJobsCompletedBy,
      async () => {
        const inProgressJobsSnapshot = await getDocs(
          qInProgressJobsCompletedBy
        );
        const inProgressJobsData = inProgressJobsSnapshot.docs.map(
          (doc) => doc.data() as Job
        );
        setInProgressJobs((prevJobs) => [...prevJobs, ...inProgressJobsData]);
      }
    );

    const unsubscribeCompletedJobs = onSnapshot(qCompletedJobs, async () => {
      const completedJobsSnapshot = await getDocs(qCompletedJobs);
      const completedJobsData = completedJobsSnapshot.docs.map(
        (doc) => doc.data() as Job
      );
      setCompletedJobs(completedJobsData);
    });

    return () => {
      unsubscribeCreatedJobs();
      unsubscribeInProgressJobsCreatedBy();
      unsubscribeInProgressJobsCompletedBy();
      unsubscribeCompletedJobs();
    };
  }, [db, userId]);

  const markJobAsDone = async (jobId: string) => {
    // console.log("Marking job as done:", jobId);
    setIsButtonDisabled(true);
    try {
      const jobRef = doc(db, "jobs", jobId);
      await updateDoc(jobRef, {
        isCompleted: true,
      });
      // console.log("Job marked as done:", jobId);
      toast.success("Trabalho concluído com sucesso!");

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      // console.error("Error marking job as done:", error);
      toast.error("Erro ao marcar o trabalho como concluído.");
    }
  };

  const deleteJob = async (jobId: any) => {
    try {
      const jobRef = doc(db, "requests", jobId);

      // Verifique se o documento existe antes de tentar excluí-lo
      const jobSnapshot = await getDoc(jobRef);

      if (jobSnapshot.exists()) {
        await deleteDoc(jobRef);
        toast.success("Trabalho apagado com sucesso!");
      } else {
        toast.info("Este trabalho não existe.");
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao excluir o trabalho.");
    }
  };

  const renderTooltip2 = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Apagar publicação
    </Tooltip>
  );

  const theme = localStorage.getItem('theme')
  return (
    <main className={`mt-5 pt-5 pg-isMobiled jobsTela pb-5 jobs ${theme}`}>
      <div className="container pt-3">
        <div className="row justify-content-center">
          <div className="col-lg-7">
          <h5 className="mb-4 text-center-d">Trabalhos publicados</h5>
            {createdJobs.length > 0 ? (
              <>
                {createdJobs.map((job) => (
                  <div key={job.jobId} className="mb-3">
                    <CardRequestsJob
                      title={job.title}
                      description={job.description}
                      date={""}
                      location={`${job.location.selectedMunicipio} / ${job.location.selectedProvincia}`}
                      details={""}
                      value={job.value}
                      skill={job.skillsRequired}
                      publish_date={job.publishDate}
                      publish_time={job.publishTime}
                      link={
                        job.requestService === true
                          ? `/serviceRequest/${job.requestId}`
                          : `/jobDetails/${job.uid}`
                      }
                      btns={
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip2}
                        >
                          <button
                            className="delete"
                            onClick={() => deleteJob(job.uid)}
                          >
                            <HiOutlineTrash />
                          </button>
                        </OverlayTrigger>
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              <div
                className="alert alert-light border-0 text-center"
                role="alert"
              >
                Nenhum trabalho encontrado.
              </div>
            )}
            <h5 className="mb-4 text-center-d mt-5">Trabalhos em andamento</h5>
            {inProgressJobs.length > 0 ? (
              <>
                {inProgressJobs.map((job) => (
                  <div key={job.jobId} className="mb-3">
                    <CardRequestsJobs
                      title={job.title}
                      description={job.description}
                      date={job.value}
                      location={`${job.location.selectedMunicipio} / ${job.location.selectedProvincia}`}
                      details={""}
                      value={job.value}
                      skill={job.skillsRequired}
                      publish_date={new Date(
                        job.publishDate
                      ).toLocaleDateString()}
                      publish_time={job.publishTime}
                      link={job.jobId}
                      btn={
                        <>                        
                          <div className="btn-viw-request-div">                           

                          {job.requestService === true
                            ? <Link
                            className="btn btn-jobDetails"
                            to={`/serviceRequest/${job.requestId}`}
                          >
                            Ver trabalho
                          </Link>
                            :  <Link
                            className="btn btn-jobDetails"
                            to={`/jobDetails/${job.jobId}`}
                          >
                            Ver trabalho
                          </Link>}                          


                            {job.createdBy === userId && !job.isCompleted && (
                              <button
                                className="btn-viw-request  ms-3 border-0 ps-3 pe-3"
                                onClick={() => markJobAsDone(job.jobId)}
                                disabled={isButtonDisabled}
                              >
                                {" "}
                                concluído
                                <HiOutlineArrowNarrowRight className="HiOutlineArrowNarrowRight" />
                              </button>
                            )}
                          </div>
                        </>
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              <div
                className="alert alert-light border-0 text-center"
                role="alert"
              >
                {" "}
                Nenhum trabalho encontrado.
              </div>
            )}

            <h5 className="mb-4 text-center-d">Trabalhos concluídos</h5>
            {completedJobs.length > 0 ? (
              <>
                {completedJobs.map((job) => (
                  <div key={job.jobId} className="mb-3">
                    <CardRequests
                      title={job.title}
                      description={job.description}
                      date={""}
                      location={`${job.location.selectedMunicipio} / ${job.location.selectedProvincia}`}
                      details={""}
                      value={job.value}
                      skill={job.skillsRequired}
                      publish_date={new Date(
                        job.publishDate
                      ).toLocaleDateString()}
                      publish_time={job.publishTime}
                      link={
                        job.requestService === true
                          ? `/serviceRequest/${job.requestId}`
                          : `/jobDetails/${job.jobId}`
                      }
                      btns={undefined}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div
                  className="alert alert-light border-0 text-center"
                  role="alert"
                >
                  Nenhum trabalho encontrado.
                </div>
              </>
            )}
            {/* <Rodape /> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>
  );
};

export default Jobs;
