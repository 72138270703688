import React, { useEffect, useState, useCallback } from "react";
import userDefalt from "./../../asset/userDefalt.png";
import logo from "./../../asset/vector/logo.svg";
import './style.scss';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, doc, getDoc, where } from "firebase/firestore";
import firebaseConfig from "../../key";
import { Skill } from "../../interface";
import { HiAdjustments, HiOutlineSearch, HiX } from "react-icons/hi";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { CardProfessionals } from "../../components/cardProfessionals";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BounceLoader } from "react-spinners";
import FiltroProfessionals from "../../components/filtroProfessionals";
import Sidebar from "react-sidebar";
// Initialize Firebase once and reuse
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const Professionals: React.FC = () => {
  //const [visibleResults, setVisibleResults] = useState(15);
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<UserInfo[]>([]);
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Skill[]>([]);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const onSetSidebarOpen = (open: boolean) => {
    setSidebarOpen(open);
  };
  interface UserInfo {
    uid: string;
    displayName: string | null;
    phoneNumber: string | null;
    photoURL: string | null;
    skills: any;
    verified: boolean;
    value: any;
    about: string;
    location: {
      selectedMunicipio: string;
      selectedProvincia: string;
    };
  }

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          const userDoc = doc(db, "users", user.uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            setUserData(docSnapshot.data());
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersQuery = query(collection(db, "users"), where("isActive", "==", true));
        const querySnapshot = await getDocs(usersQuery);
        const userData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as unknown as UserInfo[];
        setUsers(userData.filter(user => user.uid !== auth.currentUser?.uid));
      } catch (error) {
        console.error("Erro ao obter dados dos usuários:", error);
        setUsers([]);
      }
    };

    fetchUsers();
  }, [auth.currentUser]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "skills"));
        const skillData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as Skill[];
        localStorage.setItem("skills", JSON.stringify(skillData));
        setSkills(skillData);
      } catch (error) {
        console.error("Erro ao obter habilidades:", error);
        setSkills([]);
      }
    };

    fetchSkills();
  }, []);

  const handleSearch = useCallback(() => {
    setSearchExecuted(true);
    const filteredUsers = users.filter(
      (user) =>
        user.skills &&
        user.skills.some((skill: string) =>
          skill.toLowerCase().includes(searchValue.toLowerCase())
        )
    );
    setFilteredUsers(filteredUsers);
    setShowNoResults(filteredUsers.length === 0);
  }, [searchValue, users]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (newValue === "") {
      setSuggestions([]);
    } else {
      const newSuggestions = skills
        .filter((skill) =>
          skill.name.toLowerCase().includes(newValue.toLowerCase())
        )
        .slice(0, 7); // Limit to 8 suggestions
      setSuggestions(newSuggestions);
    }
  };

  const handleSuggestionClick = (suggestion: Skill) => {
    setInputValue(suggestion.name);
    setSearchValue(suggestion.name);
    setSuggestions([]);
    handleSearch();
  };

  const handleClearSearch = () => {
    setSearchExecuted(false);
    setSearchValue("");
    setFilteredUsers(users);
  };

  useEffect(() => {
    if (searchExecuted) {
      handleSearch();
    }
  }, [searchExecuted, searchValue, handleSearch]);

  const searchTooltip = (props: any) => (
    <Tooltip id="notification-tooltip" {...props}>
      Clique para Pesquisar
    </Tooltip>
  );

  const searchTooltipCloss = (props: any) => (
    <Tooltip id="notification-tooltip" {...props}>
      Clique para Limpar a Pesquisa
    </Tooltip>
  );

  const theme = localStorage.getItem('theme');

  const filteredAndSortedUsers = (searchValue !== "" ? filteredUsers : users)
    .filter((user) => {
      const filterProfessionals = localStorage.getItem('filterProfessionals');
      if (filterProfessionals) {
        const filters = JSON.parse(filterProfessionals);
        return (
          (filters.selectedMunicipio === "" || user.location.selectedMunicipio === filters.selectedMunicipio) &&
          (filters.selectedProvincia === "" || user.location.selectedProvincia === filters.selectedProvincia) &&
          (filters.selectedServiceTypes.length === 0 || filters.selectedServiceTypes.some((type: any) => user.skills.includes(type))) &&
          (filters.selectedValueRanges.length === 0 || filters.selectedValueRanges.some((range: any) => {
            const [min, max] = range.split('-').map(Number);
            const value = Number(user.value);
            return value >= min && value <= max;
          }))
        );
      }
      return true;
    })
    .slice(0/*, visibleResults*/)
    .reverse();

  return (
    <Sidebar
      sidebarClassName={`sidebarClassName ${theme}`}
      sidebar={
        <div className="container h-100">
          <FiltroProfessionals />
        </div>
      }
      open={sidebarOpen}
      onSetOpen={onSetSidebarOpen}
      overlayClassName="ddd"
    >
      <main className={`professionals ${theme}`}>
        <section>
          <div className="container pt-5 mt-5 pb-5 pg-isMobiled">
            <div className="row pt-1 pb-4 justify-content-between">
              <div className="col-lg-3 divDDDisplay">
                <div className="filter">
                  <FiltroProfessionals />
                </div>
              </div>
              <div className="col-lx-8 col-lg-8">
                <div className={`card card-search border shadow-none mb-4 ${theme}`}>
                  <div className="card-body input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Procurar por profissionais"
                      value={inputValue}
                      onChange={handleInputChange}
                      onFocus={handleInputChange}
                    />

                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={searchTooltip}
                    >
                      <button className="btn-search p-0" onClick={handleSearch}>
                        <HiOutlineSearch />
                      </button>
                    </OverlayTrigger>

                    <button
                      className="btn-search divDDisplay ms-3 p-0"
                      onClick={() => onSetSidebarOpen(true)}
                    >
                      <HiAdjustments />
                    </button>
                    {searchExecuted /*&& visibleResults > 0 */ && (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={searchTooltipCloss}
                      >
                        <button
                          className="btn-search p-0 ms-3"
                          onClick={handleClearSearch}
                        >
                          <HiX />
                        </button>
                      </OverlayTrigger>
                    )}
                  </div>
                  <div className="suggestion ps-4 pt-0">
                    {suggestions.length > 0 && (
                      <ul className="nav flex-column pb-3">
                        {suggestions.map((suggestion) => (
                          <li
                            className="nav-item-suggestion"
                            key={suggestion.id}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                {users.length > 0 ? (
                  <div>
                    {filteredAndSortedUsers.length > 0 ? (
                      filteredAndSortedUsers.map((user) => (
                        <div key={user.uid}>
                          <CardProfessionals
                            uid={user.uid}
                            displayName={user.displayName || "Nome não disponível"}
                            phoneNumber={user.phoneNumber || "Telefone não disponível"}
                            photoURL={user.photoURL || userDefalt}
                            skills={user.skills}
                            location={`${user.location.selectedProvincia || "Provincia não disponível"} / ${user.location.selectedMunicipio || "Município não disponível"}`}
                            verified={<>
                              {user.verified === true ? (
                                <div className="logo">
                                  <img src={logo} alt="" />
                                </div>
                              ) : null}
                            </>}
                            about={user.about ||
                              `Nenhuma informação disponível sobre o ${user.displayName || "usuário"}.`} value={isNaN(user.value) ? "" : user.value} />
                        </div>
                      ))
                    ) : (
                      <div className="mt-5 pt-5 text-center">
                        <h4>Nenhum resultado encontrado</h4>
                        <p>Tente alterar os filtros ou a pesquisa para encontrar outros trabalhos.</p>
                      </div>
                    )}
                    {/* {visibleResults < filteredAndSortedPosts.length && (
                    <div className="text-center mt-5">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => setVisibleResults(visibleResults + 15)}
                      >
                        Carregar Mais
                      </button>
                    </div>
                  )} */}
                  </div>
                ) : (
                  <div className="mt-5 loader d-flex justify-content-center">
                    <BounceLoader color="#4e9f3d" size={40} />
                  </div>
                )}
                <div className="row">
                  {/* {visibleResults < filteredAndSortedUsers.length && (
                  <div className="col-md-12 text-center mt-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => setVisibleResults(visibleResults + 15)}
                    >
                      Carregar Mais
                    </button>
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Sidebar>
  );
};

export default Professionals;
