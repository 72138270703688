import React, { useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import CreateJob from "./pages/CreateJob";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import JobDetails from "./pages/JobDetails";
import AuthRoute from "./auth";

import Proposals from "./pages/Proposals";
import Professionals from "./pages/Professionals";
import SavedJobs from "./pages/SavedJobs";
import Jobs from "./pages/Jobs";
import Notifications from "./pages/Notifications";
import Terms from "./pages/Terms";
import ProfessionalsDetails from "./pages/ProfessionalsDetails";
import UpdateProfile from "./pages/UpdateProfile";
// import Test from "./test";
import { Template } from "./template";
import { useAuthState } from "react-firebase-hooks/auth";
// import auth from "./auth";
import { auth } from "./services/firebaseConfig";
import { Menu, MenuIsMobile } from "./components/menu";
import Sidebar from "react-sidebar";
import { MenuSidebar } from "./components/menuSidebar";
import { HiOutlineMenu, HiOutlineViewGrid } from "react-icons/hi";
import About from "./pages/About";
import ServiceRequest from "./pages/ServiceRequest";
import Recover from "./pages/Recover";
import NewPass from "./pages/NewPass";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const authUser = useAuthState(auth);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const onSetSidebarOpen = (open: boolean) => {
    setSidebarOpen(open);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1028);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const theme = localStorage.getItem('theme')
  return <main className={`${theme}`}>
     <Router>
        {!isMobile && <Menu/>}

        <Sidebar
          sidebarClassName={`sidebarClassName ${theme}`}
          sidebar={<MenuSidebar />}
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          overlayClassName="ddd"
        >
          {isMobile && (
            <MenuIsMobile btnMenu={<button  className="btn btn-menu-bt" onClick={() => onSetSidebarOpen(true)}><HiOutlineViewGrid /></button>}/>            
          )}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
            <Route path="/diwhidwivnckvbnqkfibfkbdiwqidopdoqhfnkwqbvoqbdoiq" element={<Template />} />
            <Route path="/recover" element={<Recover/>}/>
            <Route path="/password" element={<NewPass/>}/>
            {/* <Route path="/test" element={<Test />} /> */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/updateProfile" element={<UpdateProfile />} />
            <Route
              path="/professionalsDetails/:id"
              element={<ProfessionalsDetails />}
            />
            <Route path="/jobDetails/:id" element={<JobDetails />} />
            <Route
              path="/notifications"
              element={
                <AuthRoute>
                  <Notifications />
                </AuthRoute>
              }
            />

            <Route
              path="/proposals"
              element={
                <AuthRoute>
                  <Proposals />
                </AuthRoute>
              }
            />
            <Route path="/professionals" element={
            <AuthRoute>
              <Professionals />
              </AuthRoute>
            } />
            <Route path="/serviceRequest/:id" element={
            <AuthRoute>
              <ServiceRequest />
              </AuthRoute>
            } />
            <Route
              path="/savedJobs"
              element={
                <AuthRoute>
                  <SavedJobs />
                </AuthRoute>
              }
            />
            <Route
              path="/about"
              element={
                // <AuthRoute>
                  <About />
                // </AuthRoute>
              }
            />
            <Route
              path="/jobs"
              element={
                <AuthRoute>
                  <Jobs />
                </AuthRoute>
              }
            />
            <Route
              path="createJob"
              element={
                <AuthRoute>
                  <CreateJob />
                </AuthRoute>
              }
            />
            <Route
              path="profile"
              element={
                <AuthRoute>
                <Profile />
                </AuthRoute>
              }
            />
          </Routes>
        </Sidebar>
      </Router>
  </main>
}
export default App;
