import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import firebaseConfig from "../../key";
import { Post, Skill } from "../../interface";
import { CardRequests } from "../../components/cardRequests";
import { HiAdjustments, HiOutlineHashtag, HiOutlineLocationMarker, HiOutlineSearch, HiX } from "react-icons/hi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./style.scss";
import { auth } from "../../services/firebaseConfig";
import { BounceLoader } from "react-spinners";
import 'firebase/analytics';
import { getAnalytics } from "firebase/analytics";
import FiltroJobs from "../../components/filtroJobs";
import AlertUser from "../../alerts/alertUser";
import Sidebar from "react-sidebar";

const firebaseConfigDB = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseConfigDB);

const searchTooltip = (props: any) => (
  <Tooltip id="notification-tooltip" {...props}>
    Clique para Pesquisar
  </Tooltip>
);
const searchTooltipCloss = (props: any) => (
  <Tooltip id="notification-tooltip" {...props}>
    Clique para Limpar a Pesquisa
  </Tooltip>
);

const Dashboard: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const db = getFirestore(firebaseConfigDB);
  const postCollection = collection(db, "requests");
  const skillsCollection = collection(db, "skills");
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([]);
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);
  //const [visibleResults, setVisibleResults] = useState(15);
  const uid = auth.currentUser?.uid;
  const [userData, setUserData] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Skill[]>([]);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const onSetSidebarOpen = (open: boolean) => {
     setSidebarOpen(open);
   };
  useEffect(() => {
    const getPosts = async () => {
      const querySnapshot = await getDocs(postCollection);
      const postData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as Post[];
      localStorage.setItem("postList", JSON.stringify(postData));
      const storedPosts = localStorage.getItem("postList");
      if (storedPosts) {
        const parsedPosts = JSON.parse(storedPosts) as Post[];
        setPosts(parsedPosts);
      } else {
        setPosts([]);
      }
    };
    getPosts();
  }, []);

  useEffect(() => {
    const getSkills = async () => {
      const querySnapshot = await getDocs(skillsCollection);
      const skillData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as Skill[];
      localStorage.setItem("skills", JSON.stringify(skillData));
      const storedSkills = localStorage.getItem("skills");
      if (storedSkills) {
        const parsedSkills = JSON.parse(storedSkills) as Skill[];
        setSkills(parsedSkills);
      } else {
        setSkills([]);
      }
    };
    getSkills();
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };
    getUserData();
  }, [uid, db]);

  const handleSearch = () => {
    setSearchExecuted(true);
    if (searchValue !== "") {
      const storedPosts = localStorage.getItem("postList");
      if (storedPosts) {
        const parsedPosts = JSON.parse(storedPosts) as Post[];
        const filteredPosts = parsedPosts.filter((post) =>
          post.skill.some((skill) =>
            skill.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        setShowNoResults(filteredPosts.length === 0);
        setFilteredPosts(filteredPosts);
      } else {
        setFilteredPosts([]);
      }
    }
  };

  useEffect(() => {
    if (searchExecuted) {
      handleSearch();
    }
  }, [searchExecuted, searchValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (newValue === "") {
      setSuggestions([]);
    } else {
      const newSuggestions = skills
        .filter((skill) =>
          skill.name.toLowerCase().includes(newValue.toLowerCase())
        )
        .slice(0, 7);
      setSuggestions(newSuggestions);
    }
  };

  const handleClearSearch = () => {
    setSearchExecuted(false);
    setSearchValue("");
    setFilteredPosts(posts);
    setShowNoResults(false);
  };

  const handleSuggestionClick = (suggestion: Skill) => {
    setInputValue(suggestion.name);
    setSearchValue(suggestion.name);
    setSuggestions([]);
    handleSearch();
  };

  const theme = localStorage.getItem('theme');

  const filteredAndSortedPosts = (searchValue !== "" ? filteredPosts : posts)
    .filter((post) => {
      const filterJobs = localStorage.getItem('filterJobs');
      if (filterJobs) {
        const filters = JSON.parse(filterJobs);
        return (
          (filters.selectedMunicipio === "" || post.location.selectedMunicipio === filters.selectedMunicipio) &&
          (filters.selectedProvincia === "" || post.location.selectedProvincia === filters.selectedProvincia) &&
          (filters.selectedServiceTypes.length === 0 || filters.selectedServiceTypes.some((type: any) => post.skill.includes(type))) &&
          (filters.selectedValueRanges.length === 0 || filters.selectedValueRanges.some((range: any) => {
            const [min, max] = range.split('-').map(Number);
            const value = Number(post.value);
            return value >= min && value <= max;
          }))
        );
      }
      return true;
    })
    .slice(0/*, visibleResults*/)
    .reverse();

 
  return (

    <Sidebar
    sidebarClassName={`sidebarClassName ${theme}`}
    sidebar={
      <div className="container h-100">
        <FiltroJobs />
      </div>
    }
    open={sidebarOpen}
    onSetOpen={onSetSidebarOpen}
    overlayClassName="ddd"
  >
    <main className={`dashboard ${theme}`}>
      <AlertUser />
      <section>
        <div className="container pt-5 mt-5 pb-5 pg-isMobiled">
          <div className="row pt-1 pb-4 justify-content-between">
            <div className="col-lg-3 divDDDisplay">
              <div className="filter">
                <FiltroJobs />
              </div>
            </div>
            <div className="col-lg-8 col-lx-9">
              <div className={`card card-search border shadow-none ${theme} mb-4`}>
                <div className="card-body input-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Procurar um Trabalho"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputChange}
                  />
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={searchTooltip}
                  >
                    <button
                      className="btn-search p-0"
                      onClick={() => setSearchExecuted(true)}
                    >
                      <HiOutlineSearch />
                    </button>

                    
                  </OverlayTrigger>

                  <button
                      className="btn-search divDDisplay ms-3 p-0"
                      onClick={() => onSetSidebarOpen(true)}
                    >
                     <HiAdjustments />
                    </button>
                  {searchExecuted && (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={searchTooltipCloss}
                    >
                      <button
                        className="btn-search p-0 ms-3"
                        onClick={handleClearSearch}
                      >
                        <HiX />
                      </button>
                    </OverlayTrigger>
                  )}
                </div>
                <div className="suggestion ps-4 pt-0">
                  {suggestions.length > 0 && (
                    <ul className="nav flex-column pb-3">
                      {suggestions.map((suggestion) => (
                        <li
                          className="nav-item-suggestion"
                          key={suggestion.id}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {posts.length > 0 ? (
                <div>
                  {filteredAndSortedPosts.length > 0 ? (
                    filteredAndSortedPosts.map((post) => (
                      <div key={post.id} className="mb-4">
                        <CardRequests
                          title={post.title}
                          description={post.description}
                          date={""}
                          location={`${post.location.selectedMunicipio} / ${post.location.selectedProvincia}`}
                          details={""}
                          value={post.value} // Value assumed to be a string
                          skill={post.skill}
                          publish_date={post.publish_date}
                          publish_time={post.publish_time}
                          link={`/jobDetails/${post.id}`}
                          btns={undefined}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="mt-5 pt-5 text-center">
                      <h4>Nenhum resultado encontrado</h4>
                      <p>Tente alterar os filtros ou a pesquisa para encontrar outros trabalhos.</p>
                    </div>
                  )}
                  {/* {visibleResults < filteredAndSortedPosts.length && (
                    <div className="text-center mt-5">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => setVisibleResults(visibleResults + 15)}
                      >
                        Carregar Mais
                      </button>
                    </div>
                  )} */}
                </div>
              ) : (
                <div className="mt-5 loader d-flex justify-content-center">
                  <BounceLoader color="#4e9f3d"  size={40}/>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
    </Sidebar>
  );
};

export default Dashboard;
