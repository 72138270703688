import React, { FormEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Post, Proposal, Notification, Job } from "../../interface";
import { PageLoader } from "../../components/pageLoader";
import userDefalt from "./../../asset/userDefalt.png";
import "./style.scss";
import {
  HiArrowNarrowRight,
  HiOutlineFire,
  HiOutlineLocationMarker,
  HiOutlineNewspaper,
} from "react-icons/hi";
import { auth } from "../../services/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
  doc,
  getFirestore,
  updateDoc,
  setDoc,
  getDoc,
  Firestore,
  getDocs,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import firebaseConfig from "../../key";
import { initializeApp } from "firebase/app";
import { InputForm, InputFormTextArea } from "../../components/inputForm";
import logo from "./../../asset/vector/logo.svg";
import { CardProfessionalsProposal } from "../../components/cardProfessionals";

const firebaseConfigDB = initializeApp(firebaseConfig);
const JobDetails: React.FC = () => {
  const db = getFirestore(firebaseConfigDB);
  const [userData, setUserData] = useState<any>(null);
  const [user] = useAuthState(auth);
  const { id } = useParams();
  const [job, setJob] = useState<Post | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const uid = auth.currentUser?.uid;
  const [proposals, setProposals] = useState<Proposal[]>([]);
 
  const [hasCompatibleProposal, setHasCompatibleProposal] =
    useState<boolean>(false);
  const [canSendProposal, setCanSendProposal] = useState(true);
  const textPropost = user?.uid
    ? query(collection(db, "proposals"), where("userId", "==", user.uid))
    : null;
  const [isJobExists, setIsJobExists] = useState<boolean>(false); // Estado para controlar a habilitação do botão
  const horaAtual = new Date().toLocaleTimeString();
  const dataAtual = new Date().toLocaleDateString();
  useEffect(() => {
    const getUserData = async () => {
      if (uid) {
        try {
          const userDoc = doc(db, "users", uid);
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            // console.log("Dados do usuário:", userData);
            setUserData(userData);
          } else {
            // console.log('Usuário não encontrado na coleção "users"');
          }
        } catch (error) {
          console.error("Erro ao obter dados do usuário:", error);
        }
      }
    };

    getUserData();
  }, [uid, db]);

  useEffect(() => {
    const checkJobExistence = async (jobId: string) => {
      const jobsRef = collection(db, "jobs");
      const q = query(jobsRef, where("jobId", "==", jobId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size > 0) {
        setIsJobExists(true);
      } else {
        setIsJobExists(false);
      }
    };

    if (job) {
      checkJobExistence(job.id);
    }
  }, [db, job]);

  useEffect(() => {
    if (textPropost) {
      const unsubscribe = onSnapshot(textPropost, (snapshot) => {
        const proposals: Proposal[] = snapshot.docs.map(
          (doc) => doc.data() as Proposal
        );
        const hasCompatibleProposal = proposals.some((proposal) => {
          return proposal.jobId === job?.id && proposal.status !== "rejeitada";
        });

        if (hasCompatibleProposal) {
          // console.log("Usuário já enviou uma proposta compatível");
        } else {
          // console.log("Usuário ainda não enviou uma proposta compatível");
        }
        setHasCompatibleProposal(hasCompatibleProposal);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [textPropost, job, user]);

  const q = job
  ? query(collection(db, "proposals"), where("jobId", "==", job.id))
  : null;

  useEffect(() => {
    if (q) {
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedProposals: Proposal[] = snapshot.docs.map(
          (doc) => doc.data() as Proposal
        );
        // console.log('Dados atualizados:', updatedProposals);
        setProposals(updatedProposals);
      });
  
      return () => {
        unsubscribe();
      };
    }
  }, [q, job]);
  

  useEffect(() => {
    if (job && user && job.user_id === user.uid) {
      setCanSendProposal(false);
    } else {
      setCanSendProposal(true);
    }
  }, [job, user]);
  useEffect(() => {
    const storagePost = localStorage.getItem("postList");
    if (storagePost) {
      const parsedPosts = JSON.parse(storagePost) as Post[];
      const JobData = parsedPosts.find((post) => post.id === id);
      if (JobData) {
        setJob(JobData);
      } else {
        console.log("Job not found");
      }
    } else {
      console.log("No data found in localStorage");
    }
  }, [id]);

  if (!job) {
    return <PageLoader />;
  }

  const proposal = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      reset: () => void;
    };
    const proposalText = document.querySelector<HTMLInputElement>(
      'textarea[name="proposalText"]'
    );
    const proposalValue = document.querySelector<HTMLInputElement>(
      'input[name="proposalValue"]'
    );
    if (!proposalText || !proposalValue) {
      return;
    }

    const proposalData: Proposal = {
      jobId: job?.id,
      userId: uid,
      proposalText: proposalText.value || "",
      proposalDate: new Date(),
      status: "pendente",
      value: proposalValue?.value || "",
      userPhoto: userData?.photoURL || "",
      userName: userData?.displayName || "",
      phoneUser: userData?.phoneNumber || "",
      skillUser: userData?.skills || "",
      userVerificad: userData?.verified || false,
      userMunicipio: userData?.location.selectedMunicipio || "",
      userProvincia: userData?.location.selectedProvincia || "",
    };

    try {
      await addDoc(collection(db, "proposals"), proposalData);
      const notification: Notification = {
        userId: job.user_id || "",
        message: "Enviou uma proposta",
        timestamp: `${dataAtual} - ${horaAtual}`,
        isRead: false,
        proposalId: "",
        jobId: job.id || "",
        requesterName: userData?.displayName || "",
        requesterId: userData?.uid || "",
        requesterPhoto: userData?.photoURL || "",
        requesterPhoneNumber: user?.phoneNumber || "",
        notificationId: uuidv4() || "",
        requestService: false,
        requestServiceID: "",
      };
      toast.success("Sua proposta foi enviada com sucesso!", {
        autoClose: 6000,
      });
      await addDoc(collection(db, "notifications"), notification);
      target.reset();
    } catch (error) {
      console.error("Erro ao enviar a proposta:", error);
    }
  };

  const acceptProposal = async (proposal: Proposal) => {
    setIsButtonDisabled(true);

    try {
      const jobRef = doc(db, "jobs", job.id);
      const jobDoc = await getDoc(jobRef);

      if (jobDoc.exists()) {
        // Atualizar o trabalho com a proposta aceita
        await updateDoc(jobRef, {
          acceptedProposal: proposal.jobId,
          acceptedDate: new Date(),
          completedBy: null,
          completedDate: null,
        });
      } else {
        // Criar um novo trabalho com a proposta aceita
        const newJobData: Job = {
          jobId: job.id || "",
          title: job.title || "",
          description: job.description || "",
          location: job.location || "",
          publishDate: job.publish_date || "",
          publishTime: job.publish_time || "",
          skillsRequired: job.skill || "",
          createdBy: job.user_id || "",
          createdDate: job.publish_date || "",
          isCompleted: false,
          completedBy: proposal.userId || "",
          completedDate: null,
          proposedBy: [],
          acceptedProposal: proposal.jobId || "",
          acceptedDate: new Date(),
          value: job.value || "",
          isCreatedByUser: false,
          notificationId: uuidv4() || "",
          uid: "",
          requestService: false,
          requestId: "",
        };

        const newJobRef = doc(db, "jobs", proposal.jobId);

        const newJobId = newJobRef.id;
        newJobData.notificationId = newJobId;
        await setDoc(newJobRef, newJobData);
      }

      const notification: Notification = {
        userId: proposal.userId || "",
        message: "Sua proposta foi aceita para o trabalho.",
        timestamp: `${dataAtual} - ${horaAtual}`,
        isRead: false,
        proposalId: proposal.jobId || "",
        jobId: job.id || "",
        requesterName: userData?.displayName || "",
        requesterId: userData?.uid || "",
        requesterPhoto: userData?.photoURL || "",
        requesterPhoneNumber: userData?.phoneNumber || "",
        notificationId: uuidv4(),
        requestService: false,
        requestServiceID: "",
      };

      await addDoc(collection(db, "notifications"), notification);

      toast.success("Você aceitou a proposta do trabalho com sucesso!", {
        autoClose: 6000,
      });

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      console.error("Erro ao aceitar a proposta:", error);
    }
  };
  
 
  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: job.title,
          text: job.description.slice(0, 400),
          url: `/jobDetails/${job.id}`,
        });
        console.log('Compartilhado com sucesso! ');
      } catch (error) {
        console.error('Erro ao compartilhar:', error);
      }
    } else {
      alert(`Compartilhar: ${job.description}`);
    }
  };
  
  const theme = localStorage.getItem('theme')
  return (
    <main className={`JobDetails ${theme}`}>
      <section>
        <div className="container  pg-isMobiled pt-5 mt-5 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-xl-7">
              <div className={`card cardJbbs border ${theme}`}>
                <div className="card-body mt-2">
                  <h4>{job.title}</h4>
                  <div>
                    <HiOutlineLocationMarker />{" "}
                    {`${job.location.selectedMunicipio} / ${job.location.selectedProvincia}`}{" "}
                    - Postado {job.publish_time} de {job.publish_date}
                  </div>
                  <div className="mt-4">
                    <h6>
                      <b>Descrição do Serviço</b>
                    </h6>
                    <p className="mt-3">
                      {job && job.description
                        ? job.description
                            .split("\n")
                            .map((paragraph, index) => (
                              <p key={index}>{paragraph}</p>
                            ))
                        : "Descrição do trabalho não disponível"}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h6 className="mb-3">
                      <b>Habilidades Necessárias</b>
                    </h6>
                    {Array.isArray(job.skill) &&
                      job.skill.map((skill: string, index: number) => (
                        <span className="badge me-2 mt-2" key={index}>
                          {skill}
                        </span>
                      ))}
                  </div>
                  <div className="mt-4">
                    <h6 className="mb-3">
                      <b>Detalhes do Serviço</b>
                    </h6>

                    <div>
                      <div className="badge-dt-sv">
                        Data do trabalho: {job.date}
                      </div>
                      <div className="badge-dt-sv">
                        Horario do trabalho: {job.time}
                      </div>
                      <div className="badge-dt-sv">
                        Local do trabalho: {job.location.selectedMunicipio} /{" "}
                        {job.location.selectedProvincia}
                      </div>

                      <h4 className="mt-4 mb-2">
                        Remuneração:{" "}
                        <b>
                          {new Intl.NumberFormat("pt-PT", {
                            style: "currency",
                            currency: "AOA",
                          })
                            .format(job.value)
                            .replace("AOA", "Kz")}
                        </b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
                <div className="mt-4 d-grid">
                  <button className="btn btn-gg btn-light"  onClick={handleShareClick}>Partilhar biscato</button>
                </div>
              <div className="mt-4">
                {user?.uid !== undefined ? (
                  <h4 className="mb-4">Propostas</h4>
                ) : null}

                {proposals.map((proposal) => (
                  <div key={proposal.proposalDate.toString()}>
                    <CardProfessionalsProposal
                      uid={""}
                      displayName={proposal.userName}
                      phoneNumber={proposal.phoneUser}
                      photoURL={proposal.userPhoto || userDefalt}
                      skills={proposal.skillUser}
                      location={`${proposal.userProvincia} / ${proposal.userProvincia}`}
                      verified={
                        <>
                          {proposal.userVerificad === true ? (
                            <div className="logo">
                              <img src={logo} alt="" />
                            </div>
                          ) : null}
                        </>
                      }
                      about={
                        proposal && proposal.proposalText
                          ? proposal.proposalText
                              .split("\n")
                              .map((paragraph: any, index: any) => (
                                <p key={index}>{paragraph}</p>
                              ))
                          : "Texto da proposta não disponível"
                      }
                      value={proposal?.value}
                      btns={
                        <>
                          {user &&
                            (proposal.userId !== user.uid ? (
                              <Link
                                className="btn btn-profil pt-2"
                                to={`/professionalsDetails/${proposal.userId}`}
                              >
                                Ver Perfil
                              </Link>
                            ) : (
                              <Link
                                className="btn btn-profil pt-2"
                                to={`/profile`}
                              >
                                Ver Meu Perfil
                              </Link>
                            ))}
                          {user &&
                            job.user_id === userData.uid &&
                            userData.isActive && (
                              <>
                                {isJobExists ? null : (
                                  <button
                                    className="ms-3"
                                    onClick={() => acceptProposal(proposal)}
                                    disabled={isButtonDisabled}
                                  >
                                    Aceitar
                                    <HiArrowNarrowRight className="HiArrowNarrowRight" />
                                  </button>
                                )}
                              </>
                            )}
                        </>
                      }
                    />
                  </div>
                ))}
              </div>

              {isJobExists ? (
                <div className="alert text-center mt-4">
                  Este trabalho já foi atribuído a outro profissional. 🤝
                </div>
              ) : null}

              {hasCompatibleProposal ? (
                <div className="alert alert-info mt-5">
                  Você já enviou uma proposta compatível para esta publicação e
                  seu interesse foi registrado. Aguarde por possíveis
                  atualizações ou novos contatos.
                </div>
              ) : (
                <>
                  {user?.uid !== undefined ? (
                    <>
                      {canSendProposal ? (
                        <div className="mt-4 mb-5">
                          <div className="">
                            <form onSubmit={proposal}>
                              <InputForm
                                title={"Valor da Proposta"}
                                type={"number"}
                                placeholder={""}
                                icon={<HiOutlineFire />}
                                value={undefined}
                                onChange={undefined}
                                rows={0}
                                name={"proposalValue"}
                              />
                              <div className="mt-4 mb-4">
                                <InputFormTextArea
                                  title={"Texto da Proposta"}
                                  type={""}
                                  placeholder={""}
                                  icon={<HiOutlineNewspaper />}
                                  value={undefined}
                                  onChange={undefined}
                                  rows={3}
                                  name={"proposalText"}
                                />
                              </div>
                              <button className="btn btn-primary">
                                Enviar proposta
                              </button>
                            </form>
                          </div>
                        </div>
                      ) : proposals.length === 0 ? (
                        <div className="alert alert-info mt-3">
                          Aguardando propostas para esta publicação.
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="alert alert-warning mb-4 mt-3">
                      É necessário fazer login para enviar uma proposta.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
};

export default JobDetails;
