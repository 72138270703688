import "./style.scss";

import React from "react";
import logo from "./../../asset/vector/logo.svg";
import { Link } from "react-router-dom";
import { HiLocationMarker, HiOutlineSparkles, HiPhone } from "react-icons/hi";
interface profissionais {
  uid: any; // ID exclusivo do usuário, específico para o projeto.
  displayName: any; // O nome de exibição do usuário.
  phoneNumber: any; // O número de telefone normalizado no padrão E.164 (por exemplo, +16505550101) do usuário.
  photoURL: any; // O URL da foto de perfil do usuário.
  skills: any; // Habilidades relacionadas ao usuário.
  location: any; // A localização do usuário.
  verified: any; // Indica se o usuário está verificado ou não.
  about: any; // Biografia ou descrição do usuário.
  value:any
}

interface profissionaisPropst {
  uid: string; // ID exclusivo do usuário, específico para o projeto.
  displayName: any; // O nome de exibição do usuário.
  phoneNumber: string | null; // O número de telefone normalizado no padrão E.164 (por exemplo, +16505550101) do usuário.
  photoURL: string | null; // O URL da foto de perfil do usuário.
  skills: any; // Habilidades relacionadas ao usuário.
  location: string; // A localização do usuário.
  verified: any; // Indica se o usuário está verificado ou não.
  about: string; // Biografia ou descrição do usuário.
  value: any
  btns: any
}

const theme = localStorage.getItem('theme')

export const CardProfessionals: React.FC<profissionais> = (props) => {
  return (
    <Link to={`/professionalsDetails/${props.uid}`} className={`card CardProfessionals mb-3 ${theme}`}>
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div>
              <div className="avatar">
                <img src={props.photoURL || ""} alt="" />
              </div>
              {props.verified}
            </div>
            <div className="nameUser ms-2 pt-2">
              <h6>{props.displayName}</h6>
              <span>
                {props.location} - Tel: {props.phoneNumber}
              </span>
            </div>
          </div>
          <div>
            <Link to={`/professionalsDetails/${props.uid}`} className="link">
              {/* <img src={logo} alt="" /> */}
            </Link>
          </div>
        </div>
        <div className="mt-2">
          {props.about.length > 250
            ? `${props.about.slice(0, 250)}...`
            : props.about}
        </div>
        <div className="mt-1 mb-2 d-flex align-items-center justify-content-between ">
          <div>
          {Array.isArray(props.skills) &&
            props.skills.map((skill: string, index: number) => (
              <span className="badge pe-3 ps-3 me-2 mt-2" key={index}>
                {skill}
              </span>
            ))}
          </div>
          <div>
            <h5 className="m-0">{new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "AOA",
            })
              .format(props?.value)
              .replace("AOA", "Kz")}<b>/hr</b></h5>
          </div>
        </div>
      </div>
    </Link>
  );
};

// export const CardProfessionals: React.FC<profissionais> = (props) => {
//   return (
//     <div className="card CardProfessionals mb-3">
//       <div className="card-body">
//         <div className="d-flex justify-content-between">
//         <div className="d-flex">
//           <div>
//             <div className="avatar">
//               <img src={user} alt="" />
//             </div>
//             <div className="logo">
//                 <img src={logo} alt="" />
//             </div>
//           </div>
//           <div className="nameUser ms-2 pt-2">
//             <h6>Daniel Kiatana Filipe</h6>
//             <span>Luanda / Cazenha - Tel: 999 999 999</span>
//           </div>
//         </div>
//         <div>
//             <div className="link">
//             <img src={logo} alt="" />
//             </div>
//         </div>
//         </div>
//         <div className="mt-2">
//           👋 Olá, sou o Daniel, um jovem de 26 anos com uma paixão por aprender
//           e crescer em diferentes áreas da vida. 💼 Tenho uma variedade de
//           habilidades que refletem minha vontade de me envolver em diversas
//           atividades. 📚 Além das minhas habilidades....
//         </div>
//         <div className="mt-1 mb-2">
//           <span className="badge pe-3 ps-3 me-2 mt-2">
//             Lavadeira
//           </span>
//           <span className="badge pe-3 ps-3 me-2 mt-2">
//             Engomador
//           </span>
//           <span className="badge pe-3 ps-3 me-2 mt-2">
//             Maquiador Proficional
//           </span>
//           <span className="badge pe-3 ps-3 me-2 mt-2">
//             Manicure
//           </span>
//           <span className="badge pe-3 ps-3 me-2 mt-2">
//             Cabelereiro
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };


export const CardProfessionalsProposal: React.FC<profissionaisPropst> = (props) => {
  const theme = localStorage.getItem('theme')
  return (
    <div className={`card CardProfessionals mb-3 ${theme}`}>
      <div className="card-body">
        <div >
          <div className="d-flex">
            <div>
              <div className="avatar">
                <img src={props.photoURL || ""} alt="" />
              </div>
              {props.verified}
            </div>
            <div className="nameUser ms-2 pt-2">
              <h6>{props.displayName}</h6> 
              <span>
               <HiLocationMarker/> {props.location} - <HiPhone/> Tel: {props.phoneNumber}
              </span>
            </div>
          </div>        
        <div className="mt-1 mb-3 ">
          {Array.isArray(props.skills) &&
            props.skills.map((skill: string, index: number) => (
              <span className="badge badge-pospostal pe-3 ps-3 me-2 mt-2" key={index}>
                {skill}
              </span>
            ))}
        </div>
        </div>
        
        <div className="mt-2">
          {props.about
          // .length 
          // > 250
          //   ? `${props.about.slice(0, 250)}...`
          //   : props.about
            }
        </div>
        <div className="d-flex justify-content-between bg-red">
          <div className="div-val">
          <HiOutlineSparkles/>  {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "AOA",
            })
              .format(props.value)
              .replace("AOA", "Kz")}
          </div>
        <div>
            {props.btns}
        </div>
        </div>
      </div>
    </div>
  );
};