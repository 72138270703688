import React, { useState, useEffect } from "react";
import logo from "./../../asset/vector/logo.svg";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import "./style.scss";
import { HiOutlineUser } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebaseConfig";
import { Google } from "../../components/Google";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PageLoader } from "../../components/pageLoader";

const Recover: React.FC = () => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Clique para voltar à página inicial
    </Tooltip>
  );
  const userLocal = localStorage.getItem("user");
  const [email, setEmail] = useState("");
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);
    const isButtonDisabled = email.trim() === '';
  const handleResetPassword = async () => {
    if (email.length === 0) {
      toast.info("Por favor, insira seu email antes de continuar.");
    } else {
      try {
        const success = await sendPasswordResetEmail(email);
        if (success) {
          toast.success(
            "Um email de redefinição de senha foi enviado com sucesso! Verifique sua caixa de entrada.",
            {
              autoClose: 25002,
            }
          );
          setTimeout(() => {
            window.location.href = "/";
          }, 7000);
        } else {
          toast.error(
            "O email fornecido não corresponde a uma conta existente. Certifique-se de que digitou o email correto."
          );
        }
      } catch (error) {
        // console.error("Erro ao enviar email de redefinição de senha:", error);
        toast.error(
          "Ocorreu um erro ao enviar o email de redefinição de senha. Por favor, tente novamente mais tarde."
        );
      }
    }
  };
  if (userLocal !== null) {
    window.location.href = "/dashboard";
    return <PageLoader />;
  }


  const theme = localStorage.getItem('theme')

  return (
    <>
      <section className={`p-4 pb-5 d-flex justify-content-center align-items-center body-login ${theme}`}>
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-lg-6 col-xl-3">
                <div className="text-img text-center mt-5 pb-4">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <Link to="/dashboard">
                      <img src={logo} alt="" className="mb-3" />
                    </Link>
                  </OverlayTrigger>
                  <h4>Redefinir Senha</h4>
                  Por favor, insira seu email para redefinir sua senha.
                </div>
                <form>
                  <div className="input-group border ">
                    <span className="input-group-text">
                      <HiOutlineUser />
                    </span>
                    <input
                      type="email"
                      required
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Sua Senha"
                    />
                  </div>
                  <button
                    className="btn btn-primary mt-3 mb-3"
                    onClick={handleResetPassword}
                    disabled={sending || isButtonDisabled}
                  >
                    {sending
                      ? "Enviando..."
                      : "Enviar Email de Redefinição de Senha"}
                  </button>
                </form>
                <div className="mt-5 text-center">
                  <Link className="a-link" to="/">
                  Retornar à página inicial
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
      <ToastContainer />
    </>
  );
};
export default Recover;
