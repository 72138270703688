import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { Skill } from '../../interface';
import { ToastContainer, toast } from "react-toastify";
export default function FiltroProfessionals() {
    const [skills, setSkills] = useState<Skill[]>([]);
    const [selectedProvincia, setSelectedProvincia] = useState("");
    const [selectedMunicipio, setSelectedMunicipio] = useState("");
    const [selectedValueRanges, setSelectedValueRanges] = useState<string[]>([]);
    const [selectedServiceType, setSelectedServiceType] = useState("");
    const [selectedServiceTypes, setSelectedServiceTypes] = useState<string[]>([]);
    const [suggestions, setSuggestions] = useState<Skill[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [activeFilters, setActiveFilters] = useState<any>(null);

    const isMounted = useRef(false);

    interface SelectLocation {
        selectedMunicipio: any;
        selectedProvincia: any;
    }

    interface Provincia {
        nome: string;
        municipios: string[];
    }
    const provincias: Provincia[] = [
        {
            nome: "Bengo",
            municipios: [
                "Ambriz",
                "Bula Atumba",
                "Dande",
                "Dembos",
                "Nambuangongo",
                "Pango Aluquém",
            ],
        },
        {
            nome: "Benguela",
            municipios: [
                "Baía Farta",
                "Balombo",
                "Benguela",
                "Bocoio",
                "Caimbambo",
                "Catumbela",
                "Chongoroi",
                "Cubal",
                "Ganda",
                "Lobito",
            ],
        },
        {
            nome: "Bié",
            municipios: [
                "Andulo",
                "Bailundo",
                "Camacupa",
                "Catabola",
                "Chinguar",
                "Chitembo",
                "Cuemba",
                "Cunhinga",
                "Cuito",
                "Nharea",
                "Nhârea",
            ],
        },
        {
            nome: "Cabinda",
            municipios: ["Belize", "Buco-Zau", "Cabinda", "Cacongo"],
        },
        {
            nome: "Cuando Cubango",
            municipios: [
                "Calai",
                "Cuangar",
                "Cuchi",
                "Cuito Cuanavale",
                "Dirico",
                "Longa",
                "Mavinga",
                "Menongue",
                "Nancova",
                "Rivungo",
            ],
        },
        {
            nome: "Cuanza Norte",
            municipios: [
                "Ambaca",
                "Bolongongo",
                "Cambambe",
                "Cazengo",
                "Golungo Alto",
                "Gonguembo",
                "Lucala",
                "Quiculungo",
                "Samba Cajú",
            ],
        },
        {
            nome: "Cuanza Sul",
            municipios: [
                "Amboim",
                "Cela",
                "Conda",
                "Ebo",
                "Libolo",
                "Mussende",
                "Porto Amboim",
                "Quibala",
                "Quilenda",
                "Seles",
            ],
        },
        {
            nome: "Cunene",
            municipios: [
                "Cahama",
                "Curoca",
                "Cuanhama",
                "Cuvelai",
                "Namacunde",
                "Ombadja",
            ],
        },
        {
            nome: "Huambo",
            municipios: [
                "Bailundo",
                "Cachiungo",
                "Caála",
                "Chicala-Cholohanga",
                "Chinhama",
                "Ecunha",
                "Huambo",
                "Londuimbali",
                "Longonjo",
                "Mungo",
                "Tchicala-Tcholohanga",
                "Ukuma",
            ],
        },
        {
            nome: "Huíla",
            municipios: [
                "Caconda",
                "Cacula",
                "Caluquembe",
                "Chibia",
                "Chicomba",
                "Chipindo",
                "Cuvango",
                "Gambos",
                "Humpata",
                "Jamba",
                "Lubango",
                "Matala",
                "Quilengues",
            ],
        },
        {
            nome: "Luanda",
            municipios: [
                "Belas",
                "Cacuaco",
                "Cazenga",
                "Icolo e Bengo",
                "Quiçama",
                "Luanda",
                "Talatona",
                "Viana",
            ],
        },
        {
            nome: "Lunda Norte",
            municipios: [
                "Cambulo",
                "Capenda Camulemba",
                "Caungula",
                "Chitato",
                "Cuango",
                "Cuílo",
                "Lubalo",
                "Lucapa",
                "Xá-Muteba",
            ],
        },
        {
            nome: "Lunda Sul",
            municipios: ["Cacolo", "Dala", "Muconda", "Saurimo"],
        },
        {
            nome: "Malanje",
            municipios: [
                "Calandula",
                "Cambundi-Catembo",
                "Cangandala",
                "Caombo",
                "Cunda-Dia-Baze",
                "Luquembo",
                "Malanje",
                "Marimba",
                "Massango",
                "Mucari",
                "Quela",
            ],
        },
        {
            nome: "Moxico",
            municipios: [
                "Alto Zambeze",
                "Bundas",
                "Cameia",
                "Camanongue",
                "Léua",
                "Luacano",
                "Luau",
                "Luchazes",
                "Luena",
                "Luimbale",
                "Lutembo",
            ],
        },
        {
            nome: "Namibe",
            municipios: ["Bibala", "Camucuio", "Moçâmedes", "Tômbwa", "Virei"],
        },
        {
            nome: "Uíge",
            municipios: [
                "Ambuila",
                "Bembe",
                "Buengas",
                "Bungo",
                "Damba",
                "Maquela do Zombo",
                "Mucaba",
                "Negage",
                "Puri",
                "Quitexe",
                "Quimbele",
                "Sanza Pombo",
                "Songo",
                "Uíge",
                "Zombo",
            ],
        },
        {
            nome: "Zaire",
            municipios: [
                "Cuimba",
                "M'banza Congo",
                "N'zeto",
                "Noqui",
                "Soyo",
                "Tomboco",
            ],
        },
    ];

    /*useEffect(() => {
        if (isMounted.current) {
            localStorage.setItem('filterProfessionals', JSON.stringify({
                selectedProvincia,
                selectedMunicipio,
                selectedValueRanges,
                selectedServiceTypes
            }));
        } else {
            isMounted.current = true;
        }

        return () => {
            isMounted.current = false;
        }
    }, [selectedProvincia, selectedMunicipio, selectedValueRanges, selectedServiceTypes]);*/

    const handleUpdateFilter = () => {
        localStorage.setItem('filterProfessionals', JSON.stringify({
            selectedProvincia,
            selectedMunicipio,
            selectedValueRanges,
            selectedServiceTypes,
        }));
        toast.success("Filtro atualizado", {
            autoClose: 5000,
        });
        setTimeout(()=>{
            // eslint-disable-next-line no-restricted-globals
            location.reload()
        },1000)
    };


    const removeFilter = () =>{
        localStorage.removeItem("filterProfessionals")
        toast.success("Limpando filtro", {
            autoClose: 5000,
        });
    
        setTimeout(()=>{
            // eslint-disable-next-line no-restricted-globals
            location.reload()
        },1000)
    }
    useEffect(() => {
        const filterProfessionals = localStorage.getItem('filterProfessionals');
        if (filterProfessionals) {
          const filters = JSON.parse(filterProfessionals);
          setSelectedProvincia(filters.selectedProvincia);
          setSelectedMunicipio(filters.selectedMunicipio);
          setSelectedValueRanges(filters.selectedValueRanges);
          setSelectedServiceTypes(filters.selectedServiceTypes);
          setActiveFilters(filters); // Store active filters for potential use
        }
      }, []);

    useEffect(() => {
        const getPosts = async () => {
            const storedSkills = localStorage.getItem("skills");
            if (storedSkills) {
                const parsedSkills = JSON.parse(storedSkills) as Skill[];
                setSkills(parsedSkills);
            } else {
                setSkills([]);
            }
        };
        getPosts();
    }, []);

    const handleProvinciaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProvincia(e.target.value);
        setSelectedMunicipio("");
    };

    const handleMunicipioChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMunicipio(e.target.value);
    };

    const handleValueRangeChange = (value: string) => {
        if (selectedValueRanges.includes(value)) {
            setSelectedValueRanges(selectedValueRanges.filter(range => range !== value));
        } else {
            setSelectedValueRanges([...selectedValueRanges, value]);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInputValue(newValue);

        if (newValue === "") {
            setSuggestions([]);
        } else {
            const newSuggestions = skills
                .filter((skill) =>
                    skill.name.toLowerCase().includes(newValue.toLowerCase())
                )
                .slice(0, 7);
            setSuggestions(newSuggestions);
        }
    };

    const handleSuggestionClick = (suggestion: Skill) => {
        if (selectedServiceTypes.length < 5 && !selectedServiceTypes.includes(suggestion.name)) {
            setSelectedServiceTypes([...selectedServiceTypes, suggestion.name]);
        }
        setInputValue("");
        setSuggestions([]);
    };

    const handleRemoveServiceType = (serviceType: string) => {
        setSelectedServiceTypes(selectedServiceTypes.filter(type => type !== serviceType));
    };

    const theme = localStorage.getItem('theme');

    return (
        <div className={`filter ${theme}`}>
            <h5 className="mb-3"><b>Filtrar Proficionais Por</b></h5>

            <span className="mb-3">Localização</span>

            <div className="input-group mb-4 mt-2">
                <span className="input-group-text pe-0" id="basic-addon1">
                    <HiOutlineLocationMarker />
                </span>
                <select
                    value={selectedProvincia}
                    onChange={handleProvinciaChange}
                    className="form-select"
                >
                    <option value="">Província</option>
                    {provincias.map((provincia, index) => (
                        <option key={index} value={provincia.nome}>
                            {provincia.nome}
                        </option>
                    ))}
                </select>
                <select
                    className="form-select"
                    value={selectedMunicipio}
                    onChange={handleMunicipioChange}
                >
                    <option value="">Município</option>
                    {provincias
                        .find(
                            (provincia) =>
                                provincia.nome === selectedProvincia
                        )
                        ?.municipios.map((municipio, index) => (
                            <option key={index} value={municipio}>
                                {municipio}
                            </option>
                        ))}
                </select>
            </div>
            
            <span className="mb-3">Valor do serviço</span>
            {["1000-5000", "5000-10000", "10000-30000", "30000-100000"].map((range, index) => (
                <div className="form-check mb-2" key={index}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={range}
                        id={`flexCheck${index}`}
                        checked={selectedValueRanges.includes(range)}
                        onChange={() => handleValueRangeChange(range)}
                    />
                    <label className="form-check-label">
                        <span className="text-black">{range.split('-')[0]} kz</span> até <span className="text-black">{range.split('-')[1]} kz</span>
                    </label>
                </div>
            ))}
            <div className='mb-3'></div>
            <span className="mb-3">Tipo de Serviço</span>
            <input
                type="text"
                className="form-control input-tags mt-2"
                placeholder="Pesquisar categoria"
                value={inputValue}
                onChange={handleInputChange}
            />

            <div className="selected-service-types">
                {selectedServiceTypes.map((serviceType, index) => (
                    <div key={index} className="selected-service-type">
                        {serviceType} <button onClick={() => handleRemoveServiceType(serviceType)}>x</button>
                    </div>
                ))}
            </div>

            <div className="suggestion ps-4 pb-3 pt-0">
                {suggestions.length > 0 && (
                    <ul className="nav flex-column pb-3">
                        {suggestions.map((suggestion) => (
                            <li
                                className="nav-item-suggestion"
                                key={suggestion.id}
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <button className="btn btn-primary mt-2" onClick={handleUpdateFilter}>
            Atualizar Filtro
        </button>
        <button className="btn btn-light mt-2 ms-2" onClick={removeFilter}>
            Limpar Filtro
        </button>
        <ToastContainer />
        </div>
    );
}
