import React from 'react';

const Terms: React.FC = () => {
  return <main className='mt-5 pb-5 pt-5 pb-5'>
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className='display-1'>Termos de Serviço e Política de Privacidade do Biscato</h1>
            <p className="fst-italic mb-5">Última atualização: 12/01/2024</p>
            <h5 className='mb-3'><b>1. Aceitação dos Termos de Serviço e Política de Privacidade</b></h5>
            <p className="fw-light mb-5">Ao acessar ou utilizar os serviços oferecidos pela plataforma Biscato, você concorda e aceita os termos e condições descritos neste documento, bem como a política de privacidade. Caso não concorde com estes termos, por favor, não utilize a plataforma.</p>

            <h5 className='mb-3'><b>2. Cadastro e Conta de Usuário</b></h5>
            <p className="fw-light mb-3">2.1. Para utilizar os serviços completos da plataforma Biscato, é necessário criar uma conta de usuário. Ao fazer isso, você concorda em fornecer informações precisas e completas durante o processo de cadastro.</p>
            <p className="fw-light mb-3">2.2. Você é responsável por manter a confidencialidade de suas credenciais de login e é inteiramente responsável por todas as atividades que ocorram sob sua conta.</p>
            <p className="fw-light mb-5">2.3. Caso suspeite de qualquer atividade não autorizada em sua conta, você deve notificar imediatamente a equipe de suporte do Biscato.</p>

            <h5 className='mb-3'><b>3. Proteção de Dados e Privacidade</b></h5>
            <p className="fw-light mb-3">3.1. O Biscato valoriza a privacidade dos usuários e toma medidas para proteger as informações fornecidas.</p>
            <p className="fw-light mb-3">3.2. As informações fornecidas durante o cadastro e uso da plataforma serão utilizadas de acordo com a Política de Privacidade, disponível aqui.</p>
            <p className="fw-light mb-5">3.3. O Biscato utiliza medidas de segurança para proteger as informações dos usuários, mas não pode garantir segurança absoluta. O usuário concorda em utilizar a plataforma por sua conta e risco.</p>

            <h5 className='mb-3'><b>4. Direitos Autorais e Propriedade Intelectual</b></h5>
            <p className="fw-light mb-3">4.1. Todo o conteúdo disponível na plataforma, incluindo, mas não se limitando a, textos, imagens, logotipos, e software, é propriedade exclusiva do Biscato e protegido por leis de direitos autorais.</p>
            <p className="fw-light mb-5">4.2. É estritamente proibido copiar, modificar, distribuir ou reproduzir qualquer conteúdo da plataforma sem autorização expressa do Biscato.</p>

            <h5 className='mb-3'><b>5. Uso Adequado da Plataforma</b></h5>
            <p className="fw-light mb-3">5.1. O usuário concorda em utilizar a plataforma Biscato de acordo com a legislação aplicável e estes termos de serviço.</p>
            <p className="fw-light mb-5">5.2. O usuário não deve: <br />
              {"a)"} Violar leis locais, nacionais ou internacionais;<br />
             {" b)"} Enviar conteúdo ofensivo, difamatório ou ilegal;<br />
              {"c)"} Interferir na operação da plataforma de qualquer forma;<br />
              {"d)"} Utilizar a plataforma para atividades fraudulentas.</p>

            <h5 className='mb-3'><b>6. Alterações nos Termos de Serviço e Política de Privacidade</b></h5>
            <p className="fw-light mb-3">6.1. O Biscato reserva-se o direito de alterar estes termos de serviço e a política de privacidade a qualquer momento.</p>
            <p className="fw-light mb-5">6.2. Alterações serão comunicadas aos usuários e serão efetivas após a publicação da versão revisada na plataforma.</p>

<p className='fst-italic mb-5 pb-5'>Ao utilizar os serviços do Biscato, você concorda com estes termos de serviço e a política de privacidade. Se não concordar, por favor, não continue a utilizar a plataforma.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
};

export default Terms;
